<template>
    <wizard-block>
        <template #title>Add-Ons</template>
        <template #sub-title>Vitality Benefits</template>
        
        <template #expanded-content>
            <div v-if="!medicalAidPlanAddonId">
                <bento-alert color="info mb-3" static dismiss v-model="doNotHaveAddonNotice" class="pe-5 mb-4">
                    You do not have an active Vitality addon.
                </bento-alert>
            </div>
            
            <div v-if="isEmployerRoot" class="upgrade-downgrade-notification-container">
                <bento-alert color="info mb-3" static dismiss v-model="rootVitalityNotice" class="pe-5 mb-4">
                    Please note that Root excludes Vitality membership from the company contribution.  Even though you are able to view your benefits here, the cost will be deducted off you personal bank account.
                </bento-alert>
            </div>
            
            <div class="logo-and-plan-selection">
                <div class="provider-logo mt-5">
                    <img :src="providerLogo" alt="Discovery Health"/>
                </div>
            </div>
            
            <div v-if="medicalAidPlanAddonId">
                <!--1. Vitality Plan Carousel-->
                <!--**** SELECTION MECHANISM HIDDEN FOR NOW, ROOT EMPLOYEES ONLY HAVE ACCESS TO PREMIUM ****-->
                <div class="wizard-carousel-block plan-carousel" v-if="false">
                    <h3 class="carousel-header">Discovery Vitality Plans</h3>
                    <div class="carousel-sub-header">
                        Select a plan to explore it's benefits
                    </div>
                    <wizard-block-card-carousel-addon-plan/>
                </div>
    
                <!--2. Vitality Plan Benefits Carousel-->
                <div class="wizard-carousel-block plan-benefit-carousel">
                    <h3 class="carousel-header">
                        {{ addonPlanName }} Benefits
                    </h3>
                    <div class="plan-addon-name" v-if="false">
                        {{ addonPlanName }}
                    </div>
                    <wizard-block-card-carousel-addons/>
                </div>
            </div>
        </template>
    </wizard-block>
</template>

<script setup>
    import WizardBlock from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlock"
    import WizardBlockCardCarouselAddons
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/addons/WizardBlockCardCarouselAddons"
    import WizardBlockCardCarouselAddonPlan
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/addons-plan/WizardBlockCardCarouselAddonPlan"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import {ref, computed} from "vue";
    import {useStore} from "vuex";
    const store = useStore()
    
    const doNotHaveAddonNotice = ref(true)
    const rootVitalityNotice = ref(true)

    const imageDir = store.getters.imageDir
    const medicalAidPlanAddonList = store.getters.medicalAidPlanAddonList
    const isEmployerRoot = store.getters.isEmployerRoot
    const medicalAidPlanAddonId = computed(() => store.getters.medicalAidPlanAddonId)

    const providerLogo = `${imageDir}discovery-vitality.png`
    

    const addonPlanName = computed(() => {
        const currentPlan = medicalAidPlanAddonList.find( plan => Number(plan.id) === medicalAidPlanAddonId.value)
        return (currentPlan) ? currentPlan.name : null
    })
</script>

<style lang="scss" scoped>
    .upgrade-downgrade-notification-container {
        .notice-bubble-container {
            margin-top: 10px;
        }
    }
    
    .provider-logo {
        img {
            display: block;
            margin-left: 0;
            width: 172px;
            margin-bottom: 15px;
        }
    }

    .wizard-carousel-block {
        margin-bottom: 42px;
    }
    
    h3.carousel-header {
        font-size: 22px;
        margin-bottom: 10px;
    }
    
    .carousel-sub-header {
        font-size: 14px;
        font-weight: 500;
        color: #555;
    }
    
    .plan-addon-name {
        font-weight: 600;
    }
</style>