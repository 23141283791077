<template>
    <modal-confirmation v-model="showConfirmRetirementFundChangesModal">
        <template #title>Retirement Annuity Application</template>
        <template #body>
            <div v-if="!areRetirementChangesSubmitted">
                <p>When you submit your draft changes, Bento will begin the process of notifying all the relevant parties of your desired changes.</p>
                <p>You will not be able to continue playing around with your options and until the proposed changes have been accepted and implemented, which might take up to a month to implement.</p>
                <p class="font-weight-600">By submitting these proposed changes you agree to being informed adequately about the impact these changes will have on your financial situation.</p>
            </div>
            <div v-if="isSubmittingChanges" id="submitting-changes-loader" class="mt-4">
                <loading-small v-model="isSubmittingChanges" parentSelector="#submitting-changes-loader" loadingText="Submitting changes ..."/>
            </div>
            <div class="mt-3 mb-0">
                <bento-alert color="success" static class="text-center mb-0" v-if="areRetirementChangesSubmitted && submittingChangesComplete">
                    <template #default>Your application has been submitted.</template>
                </bento-alert>
                <bento-alert color="danger" static class="text-center mb-0" v-if="submittingChangesFailed">
                    <template #default>
                        <div>Failed to submit your changes.</div>
                        <div><a href="mailto:hello@mybento.net" class="font-weight-600 alert-danger-link">Contact us for assistance.</a></div>
                    </template>
                </bento-alert>
            </div>
        </template>
        <template #button1 v-if="!isSubmittingChanges && !submittingChangesComplete">
            <button-bento @click="confirmChangesHandler" button-color="dark" class="mx-2 flex-grow-1 shadow-hover" size="lg">Confirm changes</button-bento>
        </template>
        <template #button2 v-if="!isSubmittingChanges && !submittingChangesComplete">
            <button-bento @click="cancelConfirmChangesHandler" button-color="white-bg-green" class="mx-2 flex-grow-1 shadow-hover" size="lg">Cancel</button-bento>
        </template>
    </modal-confirmation>
</template>

<script setup>
    import {ref, computed} from "vue"
    import {useStore} from "vuex"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import ModalConfirmation from "@/components/summary-and-payslip/modal/base-modals/ModalConfirmation"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall"
    
    const store = useStore()
    
    const areRetirementChangesSubmitted = computed(() => store.getters.areRetirementChangesSubmitted)
    
    const showConfirmRetirementFundChangesModal = computed({
        get: () => store.getters.showConfirmRetirementFundChangesModal,
        set: showValue => store.commit('toggleShowConfirmRetirementFundChangesModal', showValue)
    })
    
    const confirmChangesModal = ref(false)
    
    const cancelConfirmChangesHandler = () => {
        store.commit('toggleShowConfirmRetirementFundChangesModal', false)
        store.commit('toggleShowRemDraftModalRetirement', true)
    }
    
    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    const isSubmittingChanges = ref(false)
    
    const submittingChangesFailed = ref(false)
    const submittingChangesComplete = ref(false)
    
    const confirmChangesHandler = async () => {
        isSubmittingChanges.value = true
        submittingChangesComplete.value = false
        
        const retirementContractObject = {
            retirement_fund: tempSelectedRetirementFundContractObj.value.fundId,
            monthly_contribution: tempSelectedRetirementFundContractObj.value.employeeContribution,
            extra_data: tempSelectedRetirementFundContractObj.value.extraData,
            status: "submitted"
        }
        
        try {
            await store.dispatch('submitRetirementFundContractChangeRequest', retirementContractObject)
        } catch (error) {
            submittingChangesFailed.value = true
            isSubmittingChanges.value = false
            return error
        }
        
        submittingChangesComplete.value = true
        isSubmittingChanges.value = false
        
        setTimeout(() => {
            confirmChangesModal.value = false
        }, 6500)
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .reset-icon {
        width: 18px;
        position: relative;
        top: -2px;
    }
    
    p a {
        color: darken($primary, 4%);
        &:hover {
            color: darken($primary, 10%);
        }
    }
    
    #submitting-changes-loader {
        min-height: 50px;
    }
    
    #loading-retirement-fund-changes {
        min-height: 50px;
    }
</style>