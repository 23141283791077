<template>
    <wizard-block>
        <template #title>Members on Medical Aid</template>
        <template #sub-title>{{ subTitle }}</template>
        <template #expanded-content>
            <div class="member-row header-row">
                <div class="member-col-1">
                    Name
                </div>
                <div v-if="age" class="member-col-2">
                    Age
                </div>
                <div class="member-col-3">
                    ID number
                </div>
            </div>
            
            <div class="in-between-header">
                Main Member
            </div>
            
            <div class="member-row main-member">
                <div class="member-col-1">
                    {{ employee.user.first_name }} {{ employee.user.last_name }}
                </div>
                <div v-if="age" class="member-col-2">
                    {{ age }}
                </div>
                <div class="member-col-3">
                    {{ employee.id_number }}
                </div>
            </div>
            
            <div class="member-row dependants-row">
                <div class="dependants-col-1">
                    <div class="header-in-col">
                        Dependants
                    </div>
                </div>
                <div class="dependants-col-2">
                    <div class="small-gray-label">
                        Current
                    </div>
                </div>
                <div class="dependants-col-3">
                    <div class="small-gray-label">
                        Planned
                    </div>
                </div>
            </div>
            
            <div class="member-row dependants-row">
                <div class="dependants-col-1">
                    Adult Dependents
                </div>
                <div class="dependants-col-2">
                    {{ adultDependants.current }}
                </div>
                <div class="dependants-col-3">
                    <div class="input-container">
                        <div id="adult-dependants-input-container">
                            <vue-number-input
                                v-model="adultDependantsInput"
                                controls
                                inline
                                center
                                :min="0" :max="5"
                                size="small"
                                :attrs="{ id: 'adult-dependants-number-selector' }"
                            ></vue-number-input>
                        </div>
                    </div>
                </div>
                <div class="dependants-col-4">
                    <span class="dependants-note">How many adult dependants, not including yourself, will you have on your plan.</span>
                </div>
            </div>
            
            <div class="member-row dependants-row">
                <div class="dependants-col-1">
                    Child Dependents
                </div>
                <div class="dependants-col-2">
                    {{ childDependants.current }}
                </div>
                <div class="dependants-col-3">
                    <div class="input-container">
                        <div id="child-dependants-input-container">
                            <vue-number-input
                                v-model="childDependantsInput"
                                controls
                                inline
                                center
                                :min="0" :max="5"
                                size="small"
                                :attrs="{ id: 'child-dependants-number-selector' }"
                            ></vue-number-input>
                        </div>
                    </div>
                </div>
                <div class="dependants-col-4">
                    <span class="dependants-note">A child under 23 years of age and reliant on the parent for support.</span>
                </div>
            </div>
            
            
            <div class="payroll-changes-submitted" v-if="areMedicalAidChangesSubmitted">
                You have already submitted a medical aid change request.
            </div>
            <div v-else>
                <div class="edit-button save-button" v-if="!isLoadingChanges">
                    <button-bento button-color="dark" @click="saveDependantsHandler" v-if="areMedicalAidDraftChanges || hasMedicalAidContract">Save Dependants</button-bento>
                </div>
                <div id="dependant-loading-changes" v-if="isLoadingChanges">
                    <loading-small v-model="isLoadingChanges" parentSelector="#dependant-loading-changes" loadingText="Saving dependants ..."/>
                </div>
                <div>
                    <div v-if="showInfoConfirmation">
                        <bento-alert color="primary mb-3" static dismiss v-model="showInfoConfirmation" class="pe-5 mb-4">
                            Dependants saved. View your updated medical aid monthly payments below before submitting.
                        </bento-alert>
                    </div>
                    <div v-if="showUpdateProductNotice">
                        <bento-alert color="info" static dismiss v-model="showUpdateProductNotice" class="pe-5 mb-4">
                            In the next step below, you may also choose to change your medical aid plan.
                        </bento-alert>
                    </div>
                    
                    <div v-if="showBeAdvisedNotice && false">
                        <bento-alert color="secondary" static dismiss v-model="showBeAdvisedNotice">
                            <template #icon><img src="@/assets/v2/icons/alert-icons/warning.svg" alt="Warning" class="mt-1"/>
                            </template>
                            <template #title>Please be advised!</template>
                            <template #default>
                                <p>We highly recommend that you receive the adequate personal and financial advice that supports your financial decisions, as you will have to ultimately take personal responsibility for the outcomes of your choices.</p>
                                <p>For now you can play around with your options to see the potential impact on your situation, but before submitting your changes you will be requested to accept the impact of these changes.</p>
                                <p>Speak to your
                                    <router-link to="/benefits/medical-aid/meet-your-advisor/">advisor,</router-link>
                                    or feel free to
                                    <router-link :to="{ name: 'contact-us' }">ask Bento for further guidance.</router-link>
                                </p>
                            </template>
                        </bento-alert>
                    </div>
                    
                </div>
            </div>
        </template>
    </wizard-block>
</template>

<script setup>
    import { computed, ref, watch } from 'vue'
    import { useStore } from 'vuex'
    import VueNumberInput from '@chenfengyuan/vue-number-input'
    import WizardBlock from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlock"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    
    const store = useStore()
    
    let adultDependantsInput = ref(0)
    let childDependantsInput = ref(0)
    
    let showBeAdvisedNotice = ref(false)
    let showInfoConfirmation = ref(false)
    let isLoadingChanges = ref(false)
    let showUpdateProductNotice = ref(false)
    
    // Computed properties from mapGetters
    const employee = computed(() => store.getters.employee)
    const age = computed(() => store.getters.age)
    const adultDependants = computed(() => store.getters.adultDependants)
    const childDependants = computed(() => store.getters.childDependants)
    const areMedicalAidChangesSubmitted = computed(() => store.getters.areMedicalAidChangesSubmitted)
    
    const areMedicalAidDraftChanges = computed(() => store.getters.areMedicalAidDraftChanges)
    const hasMedicalAidContract = computed(() => store.getters.hasMedicalAidContract)
    
    
    const subTitle = computed(() => {
        if (store.getters.hasMedicalAidContract) {
            return `1 Main, ${store.getters.adultDependants.current} Adult, ${store.getters.childDependants.current} Child`
        } else {
            return 'No Current Plan'
        }
    })
    
    watch(adultDependantsInput, async (newValue) => {
        const tempContractObj = {
            ...store.getters.tempSelectedMedicalAidContractObj,
            adult_dependents: newValue,
        }
        await store.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj)
    })
    
    watch(childDependantsInput, async (newValue) => {
        const tempContractObj = {
            ...store.getters.tempSelectedMedicalAidContractObj,
            child_dependents: newValue,
        }
        await store.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj)
    })
    
    if (store.getters.tempSelectedMedicalAidContractObj && store.getters.tempSelectedMedicalAidContractObj.adult_dependents != null) {
        adultDependantsInput.value = store.getters.tempSelectedMedicalAidContractObj.adult_dependents
    } else {
        adultDependantsInput.value = 0
    }
    
    if (store.getters.tempSelectedMedicalAidContractObj && store.getters.tempSelectedMedicalAidContractObj.child_dependents != null) {
        childDependantsInput.value = store.getters.tempSelectedMedicalAidContractObj.child_dependents
    } else {
        childDependantsInput.value = 0
    }
    
    const saveDependantsHandler = async () => {
        showBeAdvisedNotice.value = false
        showInfoConfirmation.value = false
        showUpdateProductNotice.value = false
        
        const selectedAdultDependants = Number(adultDependantsInput.value)
        const selectedChildDependants = Number(childDependantsInput.value)
        
        isLoadingChanges.value = true
        
        const tempContractObj = {
            ...store.getters.tempSelectedMedicalAidContractObj,
            adult_dependents: selectedAdultDependants,
            child_dependents: selectedChildDependants,
        }
        await store.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj)
        
        let planId
        if (store.getters.tempSelectedMedicalAidContractObj.plan_id) {
            planId = store.getters.tempSelectedMedicalAidContractObj.plan_id
        } else if (store.getters.hasMedicalAidContractPlanned) {
            planId = store.getters.medicalAidContractObjPlanned.plan_id
        } else if (store.getters.hasMedicalAidContract) {
            planId = store.getters.medicalAidContractObj.plan_id
        } else {
            planId = null
        }
        
        if (planId) {
            const medicalAidContractObj = {
                plan_id: planId,
                adult_dependents: selectedAdultDependants,
                child_dependents: selectedChildDependants,
            }
            try {
                await store.dispatch('setMedicalAidContractChangeRequestList', medicalAidContractObj)
                showBeAdvisedNotice.value = true
                showInfoConfirmation.value = true
                showUpdateProductNotice.value = true
            } catch(error) {
                store.commit('setShowApiErrorModal', true)
                store.commit('setApiErrorMessage', error.message)
                isLoadingChanges.value = false
                return
            }
        }
        isLoadingChanges.value = false
    }
</script>

<!--<script>-->
<!--    import {mapGetters} from "vuex"-->
<!--    import VueNumberInput from '@chenfengyuan/vue-number-input'-->
<!--    import WizardBlock from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlock"-->
<!--    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"-->
<!--    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall"-->
<!--    import store from "@/store"-->
<!--    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"-->
<!--    -->
<!--    export default {-->
<!--        name: "WizardBlockMembers",-->
<!--        components: {-->
<!--            BentoAlert,-->
<!--            LoadingSmall,-->
<!--            ButtonBento,-->
<!--            WizardBlock,-->
<!--            VueNumberInput,-->
<!--        },-->
<!--        -->
<!--        data() {-->
<!--            return {-->
<!--                -->
<!--                adultDependantsInput: 0,-->
<!--                childDependantsInput: 0,-->
<!--                -->
<!--                showBeAdvisedNotice: false,-->
<!--                showInfoConfirmation: false,-->
<!--                -->
<!--                isLoadingChanges: false,-->
<!--                -->
<!--                showUpdateProductNotice: false,-->
<!--            }-->
<!--        },-->
<!--        -->
<!--        computed: {-->
<!--            -->
<!--            ...mapGetters([-->
<!--                'employee',-->
<!--                'age',-->
<!--                'adultDependants',-->
<!--                'childDependants',-->
<!--                'medicalAidContractObj',-->
<!--                'medicalAidContractObjPlanned',-->
<!--                'hasMedicalAidContract',-->
<!--                'hasMedicalAidContractPlanned',-->
<!--                'medicalAidBenefit',-->
<!--                'medicalAidPlanList',-->
<!--                'tempSelectedMedicalAidContractObj',-->
<!--                'areMedicalAidChangesSubmitted'-->
<!--            ]),-->
<!--            -->
<!--            -->
<!--            // load the tempSelectedMedicalAidContractObj from planned, then current, action which loads the medicalAidContractChangeRequest-->
<!--            // adultPlannedLogic() {-->
<!--            //     return (this.tempSelectedMedicalAidContractObj.adult_dependents > 0) ? this.tempSelectedMedicalAidContractObj.adult_dependents : 0;-->
<!--            // },-->
<!--            // childPlannedLogic() {-->
<!--            //     return (this.tempSelectedMedicalAidContractObj.child_dependents > 0) ? this.tempSelectedMedicalAidContractObj.child_dependents : 0;-->
<!--            // },-->
<!--            -->
<!--            -->
<!--            subTitle() {-->
<!--                if (this.hasMedicalAidContract) {-->
<!--                    return `1 Main, ${this.adultDependants.current} Adult, ${this.childDependants.current} Child`;-->
<!--                } else {-->
<!--                    return 'No Current Plan';-->
<!--                }-->
<!--                -->
<!--            },-->
<!--            -->
<!--        },-->
<!--        -->
<!--        watch: {-->
<!--            -->
<!--            adultDependantsInput(newValue) {-->
<!--                this.adultWatcherHandler(newValue);-->
<!--            },-->
<!--            -->
<!--            childDependantsInput(newValue) {-->
<!--                this.childWatcherHandler(newValue);-->
<!--            }-->
<!--            -->
<!--        },-->
<!--        -->
<!--        created() {-->
<!--            if (this.tempSelectedMedicalAidContractObj && this.tempSelectedMedicalAidContractObj.adult_dependents != null) {-->
<!--                this.adultDependantsInput = this.tempSelectedMedicalAidContractObj.adult_dependents;-->
<!--            } else {-->
<!--                this.adultDependantsInput = 0;-->
<!--            }-->
<!--            -->
<!--            if (this.tempSelectedMedicalAidContractObj && this.tempSelectedMedicalAidContractObj.child_dependents != null) {-->
<!--                this.childDependantsInput = this.tempSelectedMedicalAidContractObj.child_dependents;-->
<!--            } else {-->
<!--                this.childDependantsInput = 0;-->
<!--            }-->
<!--            -->
<!--        },-->
<!--        -->
<!--        methods: {-->
<!--            -->
<!--            async adultWatcherHandler(newValue) {-->
<!--                const tempContractObj = {-->
<!--                    ...this.tempSelectedMedicalAidContractObj,-->
<!--                    adult_dependents: newValue,-->
<!--                }-->
<!--                await this.$store.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj)-->
<!--            },-->
<!--            async childWatcherHandler(newValue) {-->
<!--                const tempContractObj = {-->
<!--                    ...this.tempSelectedMedicalAidContractObj,-->
<!--                    child_dependents: newValue,-->
<!--                }-->
<!--                await this.$store.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj)-->
<!--            },-->
<!--            -->
<!--            async saveDependantsHandler() {-->
<!--                this.showBeAdvisedNotice = false-->
<!--                this.showInfoConfirmation = false-->
<!--                this.showUpdateProductNotice = false-->
<!--                -->
<!--                const selectedAdultDependants = Number(this.adultDependantsInput)-->
<!--                const selectedChildDependants = Number(this.childDependantsInput)-->
<!--                -->
<!--                this.isLoadingChanges = true-->
<!--                -->
<!--                // updating the temp plan whether we have a plan or not-->
<!--                const tempContractObj = {-->
<!--                    ...this.tempSelectedMedicalAidContractObj,-->
<!--                    adult_dependents: selectedAdultDependants,-->
<!--                    child_dependents: selectedChildDependants,-->
<!--                }-->
<!--                await this.$store.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj);-->
<!--                -->
<!--                // Send to API call update only if a plan is selected-->
<!--                let planId;-->
<!--                if (this.tempSelectedMedicalAidContractObj.plan_id) {-->
<!--                    planId = this.tempSelectedMedicalAidContractObj.plan_id;-->
<!--                } else if (this.hasMedicalAidContractPlanned) {-->
<!--                    planId = this.medicalAidContractObjPlanned.plan_id;-->
<!--                } else if (this.hasMedicalAidContract) {-->
<!--                    planId = this.medicalAidContractObj.plan_id;-->
<!--                } else {-->
<!--                    planId = null;-->
<!--                }-->
<!--                -->
<!--                -->
<!--                if (planId) {-->
<!--                    const medicalAidContractObj = {-->
<!--                        plan_id: planId,-->
<!--                        adult_dependents: selectedAdultDependants,-->
<!--                        child_dependents: selectedChildDependants,-->
<!--                    }-->
<!--                    try {-->
<!--                        await this.$store.dispatch('setMedicalAidContractChangeRequestList', medicalAidContractObj)-->
<!--                        this.showBeAdvisedNotice = true-->
<!--                        this.showInfoConfirmation = true-->
<!--                        this.showUpdateProductNotice = true-->
<!--                    } catch(error) {-->
<!--                        store.commit('setShowApiErrorModal', true)-->
<!--                        store.commit('setApiErrorMessage', error.message)-->
<!--                        this.isLoadingChanges = false-->
<!--                        return-->
<!--                    }-->
<!--                }-->
<!--                this.isLoadingChanges = false-->
<!--            },-->
<!--        },-->
<!--    }-->
<!--</script>-->




<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    /*External library component : deep styled*/
    :deep(#adult-dependants-input-container),
    :deep(#child-dependants-input-container) {
        .vue-number-input {
            button {
                cursor: pointer;
                width: 29px;
                background-color: $primary;
                &:hover {
                    background-color: lighten($primary, 2%);
                }
                
                &::after, &::before {
                    background-color: white;
                }
                &::before {
                    height: 2px;
                }
                &::after {
                    width: 2px;
                }
            }
            input#adult-dependants-number-selector,
            input#child-dependants-number-selector {
                border: 1px solid $primary;
                border-radius: 5px;
                background-color: $gradient-green-1;
                width: 90px;
            }
        }
    }
    
    /*The rest*/
    .member-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 15px;
        font-size: 14px;
        &.dependants-row {
            flex-wrap: wrap;
        }
        @media (min-width: 520px) {
            font-size: 15px;
        }
        @media (min-width: 760px) {
            &.dependants-row {
                flex-wrap: nowrap;
            }
            font-size: 16px;
        }
    }
    
    .member-col-1 {
        flex: 1 auto;
        @media (min-width: 340px) {
            flex: 0 0 125px;
        }
        @media (min-width: 520px) {
            flex: 0 0 50%;
        }
        @media (min-width: 760px) {
            flex: 0 0 30%;
        }
    }
    
    .member-col-2 {
        flex: 1 1 auto;
        text-align: center;
        display: none;
        @media (min-width: 340px) {
            display: block;
        }
        @media (min-width: 520px) {
            flex: 0 0 25%;
            text-align: left;
        }
        @media (min-width: 760px) {
            flex: 0 0 15%;
        }
    }
    
    .member-col-3 {
        flex: 0 0 112px;
        text-align: center;
        @media (min-width: 520px) {
            flex: 0 0 25%;
            text-align: left;
        }
        @media (min-width: 760px) {
            flex: 0 0 55%;
        }
    }
    
    .header-row,
    .small-gray-label {
        color: $grey-darkish;
        font-size: 13px;
        font-weight: 400;
    }
    
    .in-between-header {
        font-size: 17px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 10px;
        @include media-breakpoint-up(md) {
            font-size: 18px
        }
    }
    
    .header-in-col {
        font-size: 18px;
        font-weight: 700;
    }
    
    .main-member {
        margin-bottom: 40px;
    }
    
    .dependants-row {
        margin-top: 15px;
    }
    
    .dependants-col-1 {
        flex: 1 auto;
        @media (min-width: 340px) {
            flex: 0 0 125px;
        }
        @media (min-width: 520px) {
            flex: 0 0 50%;
        }
        @media (min-width: 760px) {
            flex: 0 0 30%;
        }
    }
    
    .dependants-col-2 {
        flex: 1 1 auto;
        text-align: center;
        display: none;
        @media (min-width: 340px) {
            display: block;
        }
        @media (min-width: 520px) {
            flex: 0 0 25%;
            text-align: left;
        }
        @media (min-width: 760px) {
            flex: 0 0 15%;
        }
    }
    
    .dependants-col-3 {
        flex: 0 0 112px;
        text-align: center;
        @media (min-width: 520px) {
            text-align: left;
            flex: 0 0 25%;
        }
        @media (min-width: 760px) {
            flex: 0 0 15%;
        }
    }
    
    .dependants-col-4 {
        flex: 0 100%;
        margin-top: 6px;
        margin-bottom: 20px;
        @media (min-width: 760px) {
            margin-top: 0;
            margin-bottom: 0;
            flex: 0 0 40%;
            max-width: 330px;
            padding-left: 15px;
        }
    }
    
    .dependants-note {
        position: relative;
        top: -2px;
        font-size: 12px;
    }
    
    .edit-button {
        margin-top: 36px;
        margin-bottom: 20px;
    }
    
    .payroll-changes-submitted {
        font-weight: 700;
        font-size: 18px;
        color: darken($primary, 10%);
        margin-top: 35px;
    }
    
    #dependant-loading-changes {
        min-height: 50px;
    }
</style>