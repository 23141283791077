<template>
    <width-container>

        <loading-full-screen v-model="isLoading" loadingText="Loading terms..."/>

        <div v-if="!isLoading">

            <div class="main-container justify-content-center">
                <div class="main-content-container">

                    <div class="terms-container flex-grow-1">
                        <page-header>Bento Terms of Service</page-header>
                        <p class="please-read">
                            Please read and accept the following very important documents to proceed to magic ;)
                        </p>

                        <div class="terms-scroll-container">

                            <div class="policy-container cursor-pointer" @click="endUserTermsExpanded = !endUserTermsExpanded">
                                <div class="policy-header">
                                    <div class="title-and-date">
                                        <h3>End User Terms of Service</h3>
                                        <div class="effective-date">
                                            {{ userTermsEffectiveDate }}
                                        </div>
                                    </div>
                                    <div class="expand-container">
                                        <three-dots-toggle/>
                                    </div>
                                </div>

                                <div class="terms-content" v-show="endUserTermsExpanded">
                                    <div class="term" v-for="term in userTermsTerms" :key="term.term_heading + Math.random()">
                                        <div class="term-heading">{{ term.term_heading }}</div>
                                        <div class="term-text" v-html="term.term_text"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="policy-container cursor-pointer" @click="privacyPolicyExpanded = !privacyPolicyExpanded">
                                <div class="policy-header">
                                    <div class="title-and-date">
                                        <h3>Privacy Policy</h3>
                                        <div class="effective-date">
                                            {{ ppEffectiveDate }}
                                        </div>
                                    </div>
                                    <div class="expand-container">
                                        <three-dots-toggle/>
                                    </div>
                                </div>

                                <div v-show="privacyPolicyExpanded" class="terms-content">
                                    <div class="term" v-for="term in ppTerms" :key="term.term_heading + Math.random()">
                                        <div class="term-heading">{{ term.term_heading }}</div>
                                        <div class="term-text" v-html="term.term_text"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="accept-decline-notices" v-if="showFormPromptFail">
                                <p class="non-accept">
                                    To use the Bento App please confirm you have read and accept the
                                    <strong>Bento End User Terms</strong> and <strong>Privacy Policy</strong>
                                </p>
                                <div class="text-center align-center">
                                    <button-bento class="m-3" color="secondary" @click="logout">Logout</button-bento>
                                </div>
                            </div>
                        </div>

                        <div class="action-buttons-container">
                            <div class="loading-changes-cont" v-if="isLoadingTermsAccept">
                                <loading-small v-model="isLoadingTermsAccept" parentSelector=".loading-changes-cont" loadingText="Loading ..."/>
                            </div>

                            <div v-else>
                                <p class="accept" v-if="isTermsAccepted">
                                    Terms accepted. Redirecting you to the app.. or
                                    <router-link to="/">click here</router-link>
                                </p>
                            </div>

                            <div class="d-flex justify-content-center">
                                <button-bento @click="acceptTermsHandler" color="primary" class="mx-3">Accept</button-bento>
                                <button-bento @click="declineTermsHandler" color="dark" class="mx-3">Decline</button-bento>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <the-footer/>
    </width-container>
</template>

<script setup>
import {ref, computed, onMounted, watch} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import TheFooter from "@/components/layouts/footer/TheFooter"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
import ThreeDotsToggle from "@/components/UI/v2/misc/ThreeDotsToggle.vue"
import {setAcceptTerms} from "@/hooks/api/api-dispatch-calls"
import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"

const store = useStore()
const router = useRouter()

// Reactive state
const isLoadingEmployee = ref(false)
const isLoadingPrivacyPolicyContent = ref(false)
const isLoadingUserTermsContent = ref(false)
const showFormPromptFail = ref(false)
const showFormPromptAccept = ref(false)
const isLoadingTermsAccept = ref(false)
const endUserTermsExpanded = ref(false)
const privacyPolicyExpanded = ref(false)

// Computed properties
const isTermsAccepted = computed(() => store.getters.isTermsAccepted)

watch(() => isTermsAccepted.value, (newValue) => {
    if (newValue) {
        // Redirect to home if terms are accepted
        router.replace({ name: 'home' })
    }
})

// Additional watch to handle timing issues
watch(() => isTermsAccepted.value, (newValue) => {
    if (newValue) {
        router.replace({ name: 'home' })
    }
}, { immediate: true })



const isLoading = computed(() =>
    isLoadingPrivacyPolicyContent.value ||
    isLoadingUserTermsContent.value ||
    isLoadingEmployee.value
)

const endUserTermsContent = computed(() => store.getters['userTermsContent'] || {})

const userTermsEffectiveDate = computed(() =>
    endUserTermsContent.value?.['effective_date_sub-title'] || ''
)

const userTermsTerms = computed(() =>
    endUserTermsContent.value?.['legal_page_content']?.[0]?.['term'] || ''
)

const privacyPolicyContent = computed(() => store.getters['privacyPolicyContent'] || {})

const ppEffectiveDate = computed(() =>
    privacyPolicyContent.value?.['effective_date_sub-title'] || ''
)

const ppTerms = computed(() =>
    privacyPolicyContent.value?.['legal_page_content']?.[0]?.['term'] || ''
)


// Methods
const logout = async () => {
    await router.push({name: 'login'})
    await store.dispatch('logout')
}

const acceptTermsHandler = async () => {
    isLoadingTermsAccept.value = true
    showFormPromptFail.value = false
    showFormPromptAccept.value = true
    await setAcceptTerms()
    isLoadingTermsAccept.value = false
    await router.replace('/')
}

const declineTermsHandler = () => {
    showFormPromptFail.value = true
    showFormPromptAccept.value = false
}


const loadUserTermsContent = async () => {
    isLoadingUserTermsContent.value = true
    try {
        await store.dispatch('fetchUserTerms')
    } catch (error) {
        console.error('Error: Could not load User Terms content!', error.message)
    }
    isLoadingUserTermsContent.value = false
}

const loadPrivacyPolicyContent = async () => {
    isLoadingPrivacyPolicyContent.value = true
    try {
        await store.dispatch('fetchPrivacyPolicy')
    } catch (error) {
        console.error('Error: Could not load Privacy Policy content!', error.message)
    }
    isLoadingPrivacyPolicyContent.value = false
}

// Lifecycle hooks
onMounted(() => {
    loadUserTermsContent()
    loadPrivacyPolicyContent()
})
</script>

<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";

.main-container {
    min-height: calc(100vh - 50px - 68px - 97px - 50px);
}

.terms-container {
    background: rgba(255, 255, 255, 0.89);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 18px 16px 30px 24px;

    @media (max-width: 440px) {
        padding: 18px 10px 30px 15px;
    }

    @include media-breakpoint-up(lg) {
        padding: 20px 20px 30px 30px;
    }
}

.terms-scroll-container {
    height: calc(100vh - 250px - 135px);
    max-height: 390px;
    padding-right: 20px;
    overflow-y: auto;
    direction: ltr;
    scrollbar-color: #d4aa70 #e4e4e4;
    scrollbar-width: thin;

    @include media-breakpoint-up(md) {
        max-height: 440px;
    }

    @include media-breakpoint-up(lg) {
        max-height: 500px;
    }

    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        border: 3px solid transparent;
        background-clip: content-box;
        background-color: $primary;
    }
}

p.please-read {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 35px;
}

p {
    line-height: 1.5;
}

.policy-container {
    margin-bottom: 35px;
    padding: 14px 30px 14px 14px;
    border: 1px solid black;
    background-color: white;

    @include media-breakpoint-up(sm) {
        padding: 17px;
    }

    @include media-breakpoint-up(sm) {
        padding: 20px;
    }

    @include media-breakpoint-up(md) {
        padding: 22px;
    }

    @include media-breakpoint-up(lg) {
        padding: 25px;
    }
}

.policy-header {
    position: relative;
}

.expand-container {
    position: absolute;
    right: -15px;
    top: 50%;

    @media (max-width: 440px) {
        right: -30px;
    }

    @include media-breakpoint-up(md) {
        right: 0;
    }
}

h3 {
    margin-top: 0;
}

.effective-date {
    font-size: 16px;
    font-weight: 500;
}

.accept-terms-form-container {
    margin: 40px 0;
    background-color: $primary-alert-bg;
    border: 1px solid $primary;
    padding: 20px;
}

.form-control {
    margin-bottom: 24px;
}

.term {
    margin: 35px 0;
}

.term-heading {
    font-weight: 600;
    margin-bottom: 15px;
}

.term-text {
    font-size: 14px;
    line-height: 1.4;
}

.accept-decline-notices {
    margin: 15px 0;
}

.non-accept {
    color: darken($gradient-pink-text-dark, 10%);
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    width: 440px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.accept {
    color: $primary;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    a {
        text-decoration: underline;
        font-weight: 700;
    }
}


</style>