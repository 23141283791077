<template>
    <div>
        <loading-full-screen v-model="isSubmittedFinancialInfo" loadingText="Updating your details ..."/>

        <modal-confirmation v-model="confirmBankAccountChangesModal">
            <template #title>Confirm bank account change request?</template>
            <template #body>
                <div class="font-size-14">
                    <div><span class="font-weight-500">Bank:</span> {{ bank }}</div>
                    <div><span class="font-weight-500">Account Number:</span> {{ accountNumber }}</div>
                    <div>
                        <span class="font-weight-500">Account Type:</span> {{ optionDisplayText(accountTypeOptions, accountType) }}
                    </div>
                    <div><span class="font-weight-500">Branch Code:</span> {{ branchCode }}</div>
                    <div>
                        <span class="font-weight-500">Account Holder:</span> {{ optionDisplayText(holderRelationshipOptions, holderRelationship) }}
                    </div>
                    <div v-if="holderName"><span class="font-weight-500">Third Party Name:</span> {{ holderName }}</div>
                </div>
            </template>
            <template #button1>
                <button-bento @click="cancelBankAccountChangesHandler" button-color="dark" class="mx-2 flex-grow-1" size="sm">Cancel</button-bento>
            </template>
            <template #button2>
                <button-bento @click="confirmBankAccountChangesHandler" class="mx-2 flex-grow-1" size="sm">Submit changes</button-bento>
            </template>
        </modal-confirmation>

        <width-container v-if="!hasEmployeeNumberAndLatestPayslip">
            <card-general>
                <bento-alert color="danger" static v-model="noPayrollNotice" id="the-alert">
                    <span>Your user has not been allocated an employee number, or does not have a payslip in payroll.<br></span>
                    <span v-if="hrContact">Get in touch with your HR contact, <a :href="`mailto:${hrContact.email}`" class="fw-500 bento-underline bento-underline-secondary hover-text-shadow">{{ hrContact.name }}</a>, to allocate you one. Or <a href="mailto:hello@mybento.net" class="fw-600 bento-underline bento-underline-secondary hover-text-shadow">contact us</a> for assistance.</span>

                    <span v-else>Contact your HR admin contact to allocate an approver, or <a href="mailto:hello@mybento.net" class="fw-500 bento-underline bento-underline-secondary hover-text-shadow">contact us</a> for assistance.</span>
                </bento-alert>
            </card-general>
        </width-container>

        <width-container v-else>
            <MDBRow tag="form" class="g-3 needs-validation" novalidate @submit.prevent="checkForm">
                <MDBCol md="12">

                    <div class="d-flex align-items-center justify-content-between page-title-and-buttons-cont" >
                        <page-header class="page-title mb-0">Financial Details</page-header>
                        <div class="edit-save-button-cont text-right" v-if="!submittedBankAccountChangeRequest && !hideBankDetails">
                            <button-bento v-if="isEditing" color="plain-transparent" @click="cancelChangesHandler" size="sm">
                                Cancel
                            </button-bento>
                            <button-bento v-if="!isEditing" color="dark" @click="editHandler" size="sm">
                                <img src="@/assets/v2/icons/edit/edit-pencil-solid-white.svg" class="me-2"/>
                                <span>Edit Details</span>
                            </button-bento>
                            <button-bento v-if="isEditing && areChanges" color="dark" size="sm" type="submit">
                                <img src="@/assets/v2/icons/edit/save-white.svg" class="me-2 save-white-icon"/>
                                <span>Save Changes</span>
                            </button-bento>
                        </div>
                    </div>

                    <div v-if="incompleteAlert">
                        <bento-alert color="danger" class="mb-2" static v-model="incompleteAlert" v-if="incompleteAlert">Please complete all required fields below with valid entries.</bento-alert>
                    </div>

                    <card-general class="mt-0" v-if="!hideBankDetails">
                        <section-header>Bank Details
                            <span v-if="submittedBankAccountChangeRequest && !isEditing">(Current)</span>
                        </section-header>

                        <div>
                            <pre></pre>
                        </div>

                        <div class="row g-3">
                            <!--bank name-->
                            <profile-info-card v-if="!isEditing" title="Bank" :value="bank"/>
                            <div class="col-md-6 card-col" v-if="isEditing">
                                <MDBSelect
                                    ref="bankOptionsRef"
                                    v-model:options="bankOptions" v-model:selected="bank"
                                    :optionHeight="44"
                                    label="Bank" :preselect="false"
                                    invalid-feedback="Please select a bank account"
                                    required
                                />
                            </div>

                            <!--account number-->
                            <profile-info-card v-if="!isEditing" title="Acc. Nr" :value="accountNumber"/>
                            <profile-info-card-input-edit
                                v-else v-model="accountNumber" :input-value="accountNumber" label="Acc. Nr"
                                invalid-feedback="Please enter a valid account number longer than 4 digits (numbers only)"
                                :custom-error-message="accountNumberInvalidNotice"
                                :is-valid-value="isAccountNumberInteger && isAccountNumberLongEnough"
                                :is-validated-value="true"
                            />

                            <!--branch code-->
                            <profile-info-card v-if="!isEditing" title="Branch Code" :value="branchCode"/>
                            <profile-info-card-input-edit
                                v-else v-model="branchCode" :input-value="branchCode" label="Branch Code"
                                invalid-feedback="Please enter a valid 6 digit branch code (numbers only)"
                                :custom-error-message="branchCodeInvalidNotice"
                                :is-valid-value="isBranchCodeInteger && isBranchCodeSixDigits"
                                :is-validated-value="true"
                            />

                            <!--account type-->
                            <profile-info-card v-if="!isEditing" title="Acc. Type" :value="optionDisplayText(accountTypeOptions, accountType)"/>
                            <div class="col-md-6 card-col" v-if="isEditing">
                                <MDBSelect
                                    ref="accountTypeOptionsRef"
                                    v-model:options="accountTypeOptions" v-model:selected="accountType"
                                    :optionHeight="44"
                                    label="Acc. Type*" :preselect="false"
                                    required
                                />
                            </div>

                            <!--account holder-->
                            <profile-info-card v-if="!isEditing" title="Acc. Holder" :value="optionDisplayText(holderRelationshipOptions, holderRelationship)"/>
                            <div class="col-md-6 card-col" v-if="isEditing">
                                <MDBSelect
                                    ref="accountHolderOptionsRef"
                                    v-model:options="holderRelationshipOptions" v-model:selected="holderRelationship"
                                    :optionHeight="44"
                                    label="Acc. Holder*" :preselect="false"
                                    required
                                />
                                <div class="extra-note">The owner of the given bank account</div>
                            </div>

                            <!--third party account holder name-->
                            <profile-info-card v-if="!isEditing && holderRelationship === 'third party'" title="Third Party Name" :value="holderName"/>
                            <profile-info-card-input-edit
                                v-if="isEditing && holderRelationship === 'third party'" v-model="holderName" :input-value="holderName" label="Third Party Name" :is-required="true"
                                extra-note="The name of the third party account holder"
                            />
                        </div>


                        <!--Submitted changes-->
                        <div class="row g-3 mt-4" v-if="submittedBankAccountChangeRequest">
                            <div class="d-flex justify-content-between align-items-center">
                                <h4 class="m-0">Submitted Changes</h4>
                            </div>

                            <profile-info-card title="Bank" :value="bankSubmitted" color="pink"/>
                            <profile-info-card title="Acc. Nr" :value="accountNumberSubmitted" color="pink"/>
                            <profile-info-card title="Branch Code" :value="branchCodeSubmitted" color="pink"/>
                            <profile-info-card title="Acc. Type" :value="optionDisplayText(accountTypeOptions, accountTypeSubmitted)" color="pink"/>
                            <profile-info-card title="Acc. Holder" :value="optionDisplayText(holderRelationshipOptions, holderRelationshipSubmitted)" color="pink"/>
                            <profile-info-card title="Third Party Name" :value="holderNameSubmitted" color="pink" v-if="holderRelationshipSubmitted === 'third party'"/>

                            <div class="px-2">
                                <bento-alert color="info" static dismiss v-model="verificationAlert" icon-type="info">Your requested changes are being processed and will be updated once verification is complete.</bento-alert>
                            </div>
                        </div>
                    </card-general>

                    <div class="edit-save-button-cont text-right mt-n3" v-if="!submittedBankAccountChangeRequest && !hideBankDetails">
                        <button-bento v-if="isEditing" color="plain-transparent" @click="cancelChangesHandler" size="sm">
                            Cancel
                        </button-bento>
                        <button-bento v-if="!isEditing" color="dark" @click="editHandler" size="sm">
                            <img src="@/assets/v2/icons/edit/edit-pencil-solid-white.svg" class="me-2"/>
                            <span>Edit Details</span>
                        </button-bento>
                        <button-bento v-if="isEditing && areChanges" color="dark" size="sm" type="submit">
                            <img src="@/assets/v2/icons/edit/save-white.svg" class="me-2 save-white-icon"/>
                            <span>Save Changes</span>
                        </button-bento>
                    </div>

                    <card-general>
                        <section-header>Income Tax Detail</section-header>
                        <div class="row g-3">
                            <profile-info-card title="IRP5" :value="IRP5" :editable="false" :show-edit-icon="false" download-link="https://mybento.net"/>
                            <profile-info-card title="Income Tax Nr" :value="incomeTaxNumber"/>
                        </div>
                    </card-general>

                    <card-general class="green">
                        <section-header>Remuneration Summary</section-header>
                        <div class="class mt-n2 gradient-green-text-dark font-weight-500 font-size-15">
                            <div>Date employed &bull; {{ longDateFormat(dateOfEmployment) }}</div>
                            <div class="mt-3">
                                <profile-rem-summary-line-item
                                    v-for="stat in summaryStats"
                                    :key="stat.id"
                                    :stat="stat"
                                    stat-type="current"
                                />
                            </div>
                        </div>
                        <div class="mt-4">
                            <button-bento @click="$router.push({name: 'employee-payslip'})" id="view-payslip-button" color="white-bg-green">View Full Payslip
                                <img src="@/assets/v2/icons/arrows/button-arrow-green.svg" class="ms-2 button-arrow green-button-arrow"/>
                                <img src="@/assets/v2/icons/arrows/button-arrow-white.svg" class="ms-2 button-arrow white-button-arrow"/>
                            </button-bento>
                        </div>
                    </card-general>

                </MDBCol>
            </MDBRow>

            <footer-contact-help-card/>
        </width-container>
    </div>
</template>
<script setup>
import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
import {MDBCol, MDBRow, MDBSelect} from "mdb-vue-ui-kit"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
import {useStore} from "vuex"
import {computed, ref, watch} from 'vue';
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
import ProfileInfoCard from "@/components/employee-profile-directory/ui-components/ProfileInfoCard.vue"
import {longDateFormat} from "@/hooks/general/date-helpers"
import ProfileRemSummaryLineItem
    from "@/components/employee-profile-directory/ui-components/ProfileRemSummaryLineItem.vue"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen.vue"
import ModalConfirmation from "@/components/summary-and-payslip/modal/base-modals/ModalConfirmation.vue"
import ProfileInfoCardInputEdit
    from "@/components/employee-profile-directory/ui-components/ProfileInfoCardInputEdit.vue"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
import {isLongerThan, isRequiredValidate} from "@/hooks/general/form-validators"
import bankNamesSimplePay from "@/data/misc/bank-names-simple-pay"
import FooterContactHelpCard from "@/components/layouts/footer/FooterContactHelpCard.vue"
import {toTitleCase} from "@/hooks/general/text-formatters";

const store = useStore()

const noPayrollNotice = ref(true)
const hrContact = store.getters.hrContact
const hasEmployeeNumberAndLatestPayslip = computed(() => store.getters.hasEmployeeNumberAndLatestPayslip)

const verificationAlert = ref(true)
const employee = computed(() => store.getters.employee)

const hideBankDetails = store.getters.hideBankDetails

const originalBank = ref(toTitleCase(employee.value.bank_account?.bank) ?? null)
const originalAccountNumber = ref(employee.value.bank_account?.account_number ?? null)
const originalAccountType = ref(employee.value.bank_account?.account_type ?? null)
const originalBranchCode = ref(employee.value.bank_account?.branch_code ?? null)
const originalHolderRelationship = ref(employee.value.bank_account?.holder_relationship ?? 'employee')
const originalHolderName = ref(employee.value.bank_account?.holder_name ?? null)

const bank = ref(originalBank.value)
const accountNumber = ref(originalAccountNumber.value)
const accountType = ref(originalAccountType.value)
const branchCode = ref(originalBranchCode.value)
const holderRelationship = ref(originalHolderRelationship.value)
const holderName = ref(originalHolderName.value)

const areChanges = computed(() => {
    return (
        bank.value !== originalBank.value ||
        accountNumber.value !== originalAccountNumber.value ||
        accountType.value !== originalAccountType.value ||
        branchCode.value !== originalBranchCode.value ||
        holderRelationship.value !== originalHolderRelationship.value ||
        holderName.value !== originalHolderName.value
    )
})

// 1. Bank
const bankOptionsRef = ref(null)
const bankOptions = bankNamesSimplePay.map(element => ({
    text: element.bankName,
    value: element.bankName,
    selected: element.bankName === bank.value
}))

// 2. Account Number - String - plain text (same for SP and PP)

// 3. Branch Code - String - plain text (same for SP and PP)

// 4. Account Type
const accountTypeOptionsRef = ref(null)

const accountTypeOptions = [
    {text: "Savings", value: 'savings', selected: accountType.value === 'savings'},
    {text: "Transmission", value: 'transmission', selected: accountType.value === 'transmission'},
    {text: "Bond", value: 'bond', selected: accountType.value === 'bond'},
    {text: "Subscription Share", value: 'subscription share', selected: accountType.value === 'subscription share'},
    {text: "Current (Cheque)", value: 'current', selected: accountType.value === 'current'},
]

// 5. Account Holder
const accountHolderOptionsRef = ref(null)

const holderRelationshipOptions = [
    {text: "Self Held Account", secondaryText: "The bank account is held solely by you", value: 'employee', selected: holderRelationship.value === 'employee'},
    {text: "Joint Account", secondaryText: "The account is a joint account", value: 'joint', selected: holderRelationship.value === 'joint'},
    {text: "Third Party", secondaryText: "Account is held by a third party", value: 'third party', selected: holderRelationship.value === 'third party'},
]

// 6. Third party holder name - plain text

const optionDisplayText = (optionArray, optionValue) => {
    if (optionValue && optionValue.trim()) {
        const found = optionArray.find(element => element.value === optionValue)
        return found ? found.text : null
    }
    return null
}


// Income Tax Nr + IRP5
const incomeTaxNumber = ref(employee.value.income_tax_number)
const IRP5 = ref('')

// rem summary
const dateOfEmployment = ref(employee.value.date_of_employment)
const summaryStats = computed(() => store.getters.summaryStats)

// submitted request
const submittedBankAccountChangeRequest = ref(store.getters.submittedBankAccountChangeRequest);
const bankSubmitted = computed(() => submittedBankAccountChangeRequest.value?.bank ?? null);
const accountNumberSubmitted = computed(() => submittedBankAccountChangeRequest.value?.account_number ?? null);
const accountTypeSubmitted = computed(() => submittedBankAccountChangeRequest.value?.account_type ?? null);
const branchCodeSubmitted = computed(() => submittedBankAccountChangeRequest.value?.branch_code ?? null);
const holderRelationshipSubmitted = computed(() => submittedBankAccountChangeRequest.value?.holder_relationship ?? null);
const holderNameSubmitted = computed(() => submittedBankAccountChangeRequest.value?.holder_name ?? null);


const isEditing = ref(false)
const confirmBankAccountChangesModal = ref(false)

// 1. edit details --------------
const editHandler = async () => {
    isEditing.value = true
}

const cancelChangesHandler = () => {
    resetValuesToCurrent()
    isEditing.value = false
    incompleteAlert.value = false
    accountNumberInvalidNotice.value = null
    isFormValidated.value = false
}


// 1.5 Verify fields
const isFormValidated = ref(false)
const requiredFieldsComplete = computed(() => {
    if (
        isRequiredValidate(bank.value) &&
        isRequiredValidate(accountNumber.value) &&
        isRequiredValidate(branchCode.value) &&
        isRequiredValidate(accountType.value) &&
        isRequiredValidate(holderRelationship.value) &&
        (holderRelationship.value !== 'third party' || isRequiredValidate(holderName.value))
    ) {
        return true
    }
    return false
})

// account number validation
const accountNumberInvalidNotice = ref(null)
const isAccountNumberInteger = computed(() => Number.isInteger(+accountNumber.value))
const isAccountNumberLongEnough = computed(() => isLongerThan(accountNumber.value, 4))

// branch code validation
const branchCodeInvalidNotice = ref(null)
const isBranchCodeInteger = computed(() => Number.isInteger(+branchCode.value?.trim()) ?? false)
const isBranchCodeSixDigits = computed(() => branchCode.value?.trim().length === 6 ?? false)


// clear the warnings when values are being updated to valid entries
watch(accountNumber, () => {
    if (isAccountNumberLongEnough.value && isAccountNumberInteger.value) {
        accountNumberInvalidNotice.value = null
    }
})
watch(branchCode, () => {
    if (isBranchCodeInteger.value && isBranchCodeSixDigits.value) {
        branchCodeInvalidNotice.value = null
    }
})

const incompleteAlert = ref(false)

const checkForm = (event) => {
    incompleteAlert.value = false
    isFormValidated.value = true
    event.target.classList.add("was-validated")

    if (
        requiredFieldsComplete.value &&
        isAccountNumberInteger.value &&
        isAccountNumberLongEnough.value &&
        isBranchCodeInteger.value &&
        isBranchCodeSixDigits.value
    ) {
        confirmBankAccountChangesModal.value = true
    }

    // account number validation
    if (requiredFieldsComplete.value) {
        if (!isAccountNumberInteger.value || !isAccountNumberLongEnough.value) {
            incompleteAlert.value = true
            accountNumberInvalidNotice.value = 'Please enter a valid account number longer than 4 digits (numbers only)'
        }
    } else {
        incompleteAlert.value = true
    }

    // branch code validation
    if (requiredFieldsComplete.value) {
        if (!isBranchCodeInteger.value || !isBranchCodeSixDigits.value) {
            incompleteAlert.value = true
            branchCodeInvalidNotice.value = 'Please enter a valid 6 digit branch code (numbers only)'
        }
    } else {
        incompleteAlert.value = true
    }

}


// 2. confirm / cancel the request ----------

const confirmBankAccountChangesHandler = () => updateFinancialInfo()

const resetValuesToCurrent = () => {
    bank.value = originalBank.value
    bankOptionsRef.value?.setValue(originalBank.value)
    accountNumber.value = originalAccountNumber.value
    accountType.value = originalAccountType.value
    accountTypeOptionsRef.value?.setValue(originalAccountType.value)
    branchCode.value = originalBranchCode.value
    holderRelationship.value = originalHolderRelationship.value
    accountHolderOptionsRef.value?.setValue(originalHolderRelationship.value)
    holderName.value = originalHolderName.value
}

const cancelBankAccountChangesHandler = () => {
    isEditing.value = false
    confirmBankAccountChangesModal.value = false
    resetValuesToCurrent()
}


// 3. submit the request ---------------
const isSubmittedFinancialInfo = ref(false)
const updateFinancialInfo = async () => {
    isSubmittedFinancialInfo.value = true
    confirmBankAccountChangesModal.value = false
    isEditing.value = false

    const changeRequest = {
        "account_number": accountNumber.value,
        "account_type": accountType.value,
        "branch_code": branchCode.value,
        "bank": bank.value,
        "holder_name": holderName.value,
        "holder_relationship": holderRelationship.value,
        "status": 'submitted'
    }

    try {
        submittedBankAccountChangeRequest.value = await store.dispatch('updateFinancialInfo', changeRequest)
        resetValuesToCurrent()
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorModalTitle', 'Error updating your financial info')
        store.commit('setApiErrorMessage', error.message)

        isSubmittedFinancialInfo.value = false
        confirmBankAccountChangesModal.value = false
        return
    }

    isSubmittedFinancialInfo.value = false
}


const loadBankAccountChangeRequestList = async (refresh = false) => {
    try {
        await store.dispatch('loadBankAccountChangeRequestList', {forceRefresh: refresh});
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorModalTitle', 'Error loading your bank change request details')
        store.commit('setApiErrorMessage', error.message)
    }
}

const apiCalls = async () => await loadBankAccountChangeRequestList()
apiCalls()
</script>

<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";

.page-title-and-buttons-cont {
    flex-wrap: wrap;
    margin-bottom: 24px;
    @media (min-width: 544px) {
        flex-wrap: nowrap;
    }
}

.page-title {
    margin-top: 6px;
    flex: 0 0 100%;
    @media (min-width: 544px) {
        flex: 1;
    }
}

.edit-save-button-cont {
    flex: 0 0 100%;
    margin-top: 8px;
    @media (min-width: 544px) {
        margin-top: 0;
        flex: 1;
        text-align: right;
    }
    .btn {
        margin-top: 4px;
    }
}

.save-white-icon {
    width: 17px;
    position: relative;
    top: -1px;
}

.edit-image-icon {
    border-radius: 3px;
    padding: 4px;
    width: 34px;
    top: 20px;
    left: 20px;
    background: rgba(black, .05);
    z-index: 999;
    text-align: center;
    img {
        width: 100%;
        &.cancel-img {
            max-width: 20px;
        }
    }
}

.btn-white-bg-green {
    .white-button-arrow {
        display: none;
    }
    &:hover {
        .white-button-arrow {
            display: inline-block;
        }
        .green-button-arrow {
            display: none;
        }
    }
}

.button-arrow {
    position: relative;
    top: -1px;
}

// for MDB form elements directly used in here
:deep(.form-outline) {
    .form-control {
        min-height: 56px;
        color: $gradient-green-text-dark;
    }
    label.form-label {
        font-size: 13px;
    }
}

.extra-note {
    width: 100%;
    font-size: 11px;
    color: $gray-main;
}
</style>