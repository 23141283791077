<template>
    <wizard-block>
        <template #title>Medical Aid Product</template>
        <template #sub-title>{{ subtitle }}</template>

        <template #expanded-content>

            <div class="upgrade-downgrade-notification-container">
                <bento-alert color="info mb-3" static dismiss v-model="downgradeUpgradeExplanation" class="pe-5 mb-4" icon-type="info">
                    Please note that you can downgrade your medical aid plan at any stage, but upgrades are only allowed once a year during the month of December.
                </bento-alert>
            </div>

            <div class="provider-logo-and-plan-labels">
                <div class="provider-logo">
                    <div><img :src="providerLogo" alt="Discovery"/></div>
                    <div class="gradient-green-text-dark"><a class="bento-underline gradient-green-text-dark fw-500 font-size-16" href="https://content.mybento.net/media/assets/app/medical-aid/discovery/discovery-health-medical-scheme-plan-comparison-2025.pdf" target="_blank">2025 Discovery Plan Comparison</a> -></div>
                </div>

                <div class="plan-name-container">
                    <div class="plan-name">
                        <div class="plan-name-label">
                            <strong>Current Plan:</strong>
                        </div>
                        <div class="plan-name-value">
                            {{ currentMedicalAidPlanName }}
                        </div>
                    </div>
                    <div class="plan-name">
                        <div class="plan-name-label">
                            <strong>Selected Plan:</strong>
                        </div>
                        <div class="plan-name-value">
                            {{ selectedMedicalAidPlanName }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="wizard-separator-line"></div>

            <!--Three Carousels: Series, Plan, Benefits - cascading effect of selection-->

            <!--1. Series Carousel-->
            <div class="wizard-carousel-block series-carousel">
                <h3 class="carousel-header mt-4">Discovery Plan Series</h3>
                <div class="carousel-sub-header">
                    <div v-if="false">Select a series to explore plans.</div>
                    <div v-if="false">
                        <a class="bento-underline gradient-green-text-dark" href="https://content.mybento.net/media/assets/app/medical-aid/discovery/discovery-health-medical-scheme-plan-comparison-2025.pdf" target="_blank">Click here for a detailed plan comparison</a>
                    </div>
                </div>
                <wizard-block-card-carousel-series/>
            </div>

            <!--2. Plan Carousel-->
            <div class="wizard-carousel-block plan-carousel">
                <h3 class="carousel-header">Discovery Plan Selected</h3>
                <div class="carousel-sub-header" v-if="false">
                    Select a plan to explore it's benefits
                </div>
                <wizard-block-card-carousel-plan/>
            </div>


            <!--3. Benefits Carousel - HIDDEN -->
            <div class="wizard-carousel-block benefits-carousel">
                <h3 class="carousel-header">Plan Benefits</h3>
                <div class="disclaimer-text-bigger" v-if="false">
                    <i class="fas font-size-16 fa-info-circle cursor-pointer gradient-green-text-dark me-1"></i>
                    <span class="gradient-green-text-dark bento-underline"> <info-tooltip :include-tip-info-icon="false" tip-title="Discovery Prescribed Minimum Benefits (PMB)" :tip-content="pmbNoticeText"/></span>
                </div>
                <div class="gradient-green-text-dark"><a class="bento-underline gradient-green-text-dark fw-500 font-size-16" href="https://content.mybento.net/media/assets/app/medical-aid/discovery/discovery-health-medical-scheme-plan-comparison-2025.pdf" target="_blank">2025 Discovery Plan Comparison</a> -></div>
                <wizard-block-card-carousel-benefits v-if="false"/>
            </div>

            <div class="wizard-separator-line"></div>

            <div class="payroll-changes-submitted" v-if="areMedicalAidChangesSubmitted">
                You have already submitted a medical aid change request.
            </div>
            <div v-else>
                <div v-if="selectedPlanIdExists" class="product-update-button-container">
                    <button-bento @click="saveDraftMedicalAidPlan" button-color="dark">
                        Save Draft Medical Aid Plan
                    </button-bento>
                </div>

                <div v-else>
                    <bento-alert color="info mb-3" static v-model="selectAPlanNotice" class="pe-5 mb-4">
                        Select a plan above before saving
                    </bento-alert>
                </div>

                <div class="loading-changes-cont" v-if="isLoadingChanges">
                    <loading-small v-model="isLoadingChanges" parentSelector=".loading-changes-cont" loadingText="Saving new plan ..."/>
                </div>

                <div v-else>
                    <div v-if="planSavedNotice">
                        <bento-alert color="primary mb-3" static dismiss v-model="planSavedNotice" class="pe-5 mb-4">
                            Plan saved. View your updated medical aid monthly payments below before submitting.
                        </bento-alert>
                    </div>
                    <div v-if="showUpdateDependantsNotice">
                        <bento-alert color="info" static dismiss v-model="showUpdateDependantsNotice" class="pe-5 mb-4">
                            If you haven't already, you may modify the number of dependents on your plan in the previous step.
                        </bento-alert>
                    </div>
                    <div v-if="showBeAdvisedNotice">
                        <bento-alert color="secondary" static dismiss v-model="showBeAdvisedNotice">
                            <template #icon>
                                <img src="@/assets/v2/icons/alert-icons/warning.svg" alt="Warning" class="mt-1"/>
                            </template>
                            <template #title>Please be advised!</template>
                            <template #default>
                                <p>We highly recommend that you receive the adequate personal and financial advice that supports your financial decisions, as you will have to ultimately take personal responsibility for the outcomes of your choices.</p>
                                <p>For now you can play around with your options to see the potential impact on your situation, but before submitting your changes you will be requested to accept the impact of these changes.</p>
                                <p>Speak to your
                                    <router-link to="/benefits/medical-aid/meet-your-advisor/">advisor,</router-link>
                                    or feel free to
                                    <router-link :to="{ name: 'contact-us' }">ask Bento for further guidance.</router-link>
                                </p>
                            </template>
                        </bento-alert>
                    </div>

                </div>
            </div>
        </template>
    </wizard-block>
</template>

<script setup>
import {ref, computed} from "vue";
import WizardBlock from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlock"

import WizardBlockCardCarouselSeries
    from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/series/WizardBlockCardCarouselSeries"
import WizardBlockCardCarouselPlan
    from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/plan/WizardBlockCardCarouselPlan"
import WizardBlockCardCarouselBenefits
    from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/benefit/WizardBlockCardCarouselBenefits"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall";
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue";
import {useStore} from "vuex";
import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue"

const store = useStore()

const selectAPlanNotice = ref(true)
const planSavedNotice = ref(false)

const pmbNoticeText = `
    <div class="text-start font-weight-400"><p>All Discovery Health Medical Scheme (DHMS) plans cover the costs related to the diagnosis, treatment and care of: an emergency medical condition, a defined list of 271 diagnoses and a defined list of 27 chronic conditions. Your medical condition must qualify for cover and be part of the defined list of Prescribed Minimum Benefit conditions. The treatment requested must match the treatments in the defined benefits. You must use designated service providers (DSPs) in our network – this does not apply in emergencies. Where appropriate and according to the rules of the Scheme, you may be transferred to a hospital or other service providers in our network once your condition has stabilised. If your treatment doesn’t meet the above criteria, we will pay up to 80% of the Discovery Health Rate (DHR). You will be responsible for the difference between what we pay and the actual cost of your treatment.</p></div>`

const showBeAdvisedNotice = ref(false)
const showInfoConfirmation = ref(false)
const showUpdateDependantsNotice = ref(false)
const downgradeUpgradeExplanation = ref(true)
const isLoadingChanges = ref(false)

const imageDir = store.getters.imageDir
const medicalAidContractObj = computed(() => store.getters.medicalAidContractObj)
const hasMedicalAidContract = computed(() => store.getters.hasMedicalAidContract)
const hasMedicalAidContractPlanned = computed(() => store.getters.hasMedicalAidContractPlanned)
const medicalAidContractObjPlanned = computed(() => store.getters.medicalAidContractObjPlanned)
const tempSelectedMedicalAidContractObj = computed(() => store.getters.tempSelectedMedicalAidContractObj)
const areMedicalAidChangesSubmitted = computed(() => store.getters.areMedicalAidChangesSubmitted)

const providerLogo = imageDir + 'discovery.png'
const subtitle = computed(() => medicalAidContractObj.value ? medicalAidContractObj.value.provider + ' ' + medicalAidContractObj.value.name : 'No Current Plan')

const selectedPlanIdExists = computed(() => !!tempSelectedMedicalAidContractObj.value.plan_id)
const currentMedicalAidPlanName = computed(() => (hasMedicalAidContract.value) ? `${medicalAidContractObj.value.provider} ${medicalAidContractObj.value.name}` : "No Current Medical Aid Plan")
const selectedMedicalAidPlanName = computed(() => (hasMedicalAidContractPlanned.value) ? `${medicalAidContractObjPlanned.value.provider} ${medicalAidContractObjPlanned.value.name}` : 'No plan selected')


// on the new wizard card selection save button
const saveDraftMedicalAidPlan = async () => {
    isLoadingChanges.value = true
    showBeAdvisedNotice.value = false
    showInfoConfirmation.value = false
    showUpdateDependantsNotice.value = false
    planSavedNotice.value = false

    let newMedicalAidContractObj
    const selectedMedicalAidPlanId = tempSelectedMedicalAidContractObj.value.plan_id

    if (Number(selectedMedicalAidPlanId) === 0 || selectedMedicalAidPlanId.length === 0) {
        newMedicalAidContractObj = null;
    } else {

        // adult dependants
        let adultDependants = 0
        if (tempSelectedMedicalAidContractObj.value.adult_dependents) {
            adultDependants = tempSelectedMedicalAidContractObj.value.adult_dependents
        } else if (hasMedicalAidContractPlanned.value) {
            adultDependants = medicalAidContractObjPlanned.value.adult_dependents
        } else if (hasMedicalAidContract.value) {
            adultDependants = medicalAidContractObj.value.adult_dependents
        }

        // child dependants
        let childDependants = 0
        if (tempSelectedMedicalAidContractObj.value.child_dependents) {
            childDependants = tempSelectedMedicalAidContractObj.value.child_dependents
        } else if (hasMedicalAidContractPlanned.value) {
            childDependants = medicalAidContractObjPlanned.value.child_dependents
        } else if (hasMedicalAidContract.value) {
            childDependants = medicalAidContractObj.value.child_dependents
        }

        newMedicalAidContractObj = {
            // id: xxx, // included in action
            plan_id: Number(selectedMedicalAidPlanId),
            // member_number: memberNumber, // set in action
            adult_dependents: adultDependants,
            child_dependents: childDependants,
            // employer_contribution: xxxx, // calculated in action
            // status: 'draft' / 'submitted', // included in action
        }
        // employee_contribution: xxxx, // not posted, calculated and updated on backend
        // total_cost: xxxx, // not posted, calculated and updated on backend
    }

    try {
        await store.dispatch('setMedicalAidContractChangeRequestList', newMedicalAidContractObj)
        showUpdateDependantsNotice.value = true
        showBeAdvisedNotice.value = true
        planSavedNotice.value = true
        showInfoConfirmation.value = true
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
        isLoadingChanges.value = false
    }
    isLoadingChanges.value = false
}
</script>

<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";

.wizard-carousel-block {
    margin-bottom: 42px;
}

.carousel-header {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
        font-size: 22px;
    }
}

.carousel-sub-header {
    font-size: 14px;
    font-weight: 500;
    color: #555;
}

.wizard-separator-line {
    height: 1px;
    width: 100%;
    background-color: rgba(black, .4);
    margin: 22px 0;
}

.provider-logo-and-plan-labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 640px) {
        flex-wrap: nowrap;
    }
}

.provider-logo {
    flex: 0 0 100%;
    @media (min-width: 640px) {
        flex: 1 auto;
    }
    img {
        display: block;
        margin-left: 0;
        width: 200px;
        margin-bottom: 16px;
    }
}

.plan-name-container {
    flex: 0 0 100%;

    @media (min-width: 640px) {
        flex: 0 0 324px;
    }
}

.plan-name {
    line-height: 1;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    font-size: 13px;
}

.plan-name-label {
    flex: 0 0 110px;
    line-height: 1.2;
}

.plan-name-value {
    flex: 1 auto;
    line-height: 1.2;
}

.product-update-button-container {
    margin-bottom: 30px;
}

.payroll-changes-submitted {
    font-weight: 700;
    font-size: 18px;
    color: darken($primary, 10%);
}
</style>