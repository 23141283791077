<template>
    <menu-item :class="classList" :is-current="isCurrent">
        <template #title>
            Essentials
        </template>
        <template #subMenuItems>
            <menu-item-second routePath="/leave">Leave</menu-item-second>
            <menu-item-second routePath="/benefits/medical-aid" v-if="isEnabledMedicalAid">Medical Aid</menu-item-second>
            <menu-item-second routePath="/benefits/insurance" v-if="isEnabledRisk || isEnabledRiskContent">Insurance</menu-item-second>
            <menu-item-second routePath="/benefits/retirement" v-if="isEnabledRetirement || isEnabledRetirementCalculator">Retirement</menu-item-second>
            
            <!--Get simple benefits that are essential benefits category -->
            <menu-item-second
                v-for="benefit in essentialBenefits" :key="benefit.id"
                :routePath="`/perk/${benefit.category}/essential-benefits/${benefit.id}/${convertToKebabCase(benefit.name)}`">{{ benefit.name }}
            </menu-item-second>
        </template>
    </menu-item>
</template>

<script setup>
    import MenuItem from "@/components/layouts/side-nav/side-nav-components/MenuItem.vue"
    import MenuItemSecond from "@/components/layouts/side-nav/side-nav-components/MenuItemSecond.vue"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import {useRoute} from 'vue-router'
    import {convertToKebabCase} from "@/hooks/general/text-formatters"
    
    const store = useStore()

    const isEnabledMedicalAid = computed(() => store.getters.isEnabledMedicalAid)
    const isEnabledRisk = computed(() => store.getters.isEnabledRisk)
    const isEnabledRiskContent = computed(() => store.getters.isEnabledRiskContent)
    const isEnabledRetirement = computed(() => store.getters.isEnabledRetirement)
    const isEnabledRetirementCalculator = computed(() => store.getters.isEnabledRetirementCalculator)
    
    // get simple benefits that are "Essential Benefits" category -> Category ID: 9
    const simpleBenefitsList = computed(() => store.getters.simpleBenefitsList)
    const essentialBenefits = computed(() => simpleBenefitsList?.value?.filter(benefit => benefit.category === 9))
    
    const route = useRoute()
    const isCurrent = computed(() => route.meta.menuParent === 'essentials')
    const classList = computed(() => {
        return {'current-parent': isCurrent.value}
    })
</script>