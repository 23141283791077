<template>
    <card-general class="pb-4" :class="{'calculator-only': !isEnabledRetirement}">
        <section-header v-if="false">Envision your retirement today</section-header>
        <retirement-interface-switch v-if="isEnabledRetirement"/>
        <div class="d-flex justify-content-between">
            <div class="fw-500 ms-1 gradient-green-text-dark font-size-16 hover-primary">
                <a class="cursor-pointer" @click="navigateToSection">Need help using the calculator?</a>
            </div>
            <div class="text-center gradient-green-text-dark todays-money-switch-container">
                <div class="fw-500 font-size-15 cursor-pointer hover-text-shadow" @click="toggleAmountsCurrentValue">
                    <info-tooltip :tip-content="extraInfoInTodaysMoney"/>
                    View as:
                </div>
                <div id="current-value-switch" class="mt-1 d-flex justify-content-center align-items-center">
                    <MDBSwitch v-model="amountsCurrentValue"/>
                </div>
                <div class="fw-500 mt-1 font-size-13">
                    <span v-if="amountsCurrentValue">Today's terms</span><span class="gradient-green-text-dark" v-else>Nominal amount</span>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <card-general-inner class="green no-shadow">
                <div class="d-flex align-items-end flex-wrap justify-content-between flex-md-nowrap mb-3 pb-1">
                    <div class="top-figures-col gradient-green-text-dark">
                        <div class="font-size-16 font-weight-600">
                            <div class="font-size-11 font-weight-500 mt-n1 ps-4 in-todays-money-notice">
                                <span v-show="amountsCurrentValue">In today's money</span>
                            </div>
                            <div>
                                <info-tooltip :tip-content="extraInfoTargetAmount"/>
                                Target amount:
                            </div>
                        </div>
                        <div class="font-weight-600" v-if="amountsCurrentValue">{{ bentoCurrencyFormat(targetAmountToday, 0) }}</div>
                        <div class="font-weight-600" v-else>{{ bentoCurrencyFormat(targetAmount, 0) }}</div>
                        <div class="disclaimer-text">Needed to retire comfortably</div>
                    </div>
                    <div class="top-figures-col gradient-green-text-dark" id="target-age-text-block">
                        <div class="font-size-16 font-weight-600">Target age:</div>
                        <div class="font-weight-600">{{ deathAge }}</div>
                        <div class="disclaimer-text">Your life expectancy</div>
                    </div>
                    <div class="top-figures-col" :class="projectedAmountClassList">
                        <div class="font-size-16 font-weight-600">
                            <div class="font-size-11 font-weight-500 mt-n1 ps-4 in-todays-money-notice">
                                <span v-show="amountsCurrentValue">In today's money</span>
                            </div>
                            <div>
                                <info-tooltip :tip-content="extraInfoProjectedAmount"/>
                                Projected amount:
                            </div>
                        </div>
                        <div class="font-weight-600" v-if="amountsCurrentValue">{{ bentoCurrencyFormat(projectedAmountToday, 0) }}</div>
                        <div class="font-weight-600" v-else>{{ bentoCurrencyFormat(projectedAmount, 0) }}</div>
                        <div class="disclaimer-text">Amount at retirement</div>
                    </div>
                    <div class="top-figures-col" id="age-col" :class="projectedAmountClassList">
                        <div class="font-size-16 font-weight-600">
                            <info-tooltip :tip-content="extraInfoAgeYouCanRetireUntil"/>
                            You can retire until:
                        </div>
                        <div class="font-weight-600 top-figures-col-value">{{ ageYouCanRetireUntil }}{{ ageYouCanRetireUntil >= 120 ? '+' : '' }}
                            <span>yrs</span></div>
                        <div class="disclaimer-text">Age your savings will run out</div>
                    </div>
                </div>
                <retirement-calculator-age-bar id="retirement-calculator-age-bar" class="mt-4 pt-1 mb-5" :age-you-can-retire-until="ageYouCanRetireUntil" :death-age="deathAge" :retirement-age="retirementAge"/>

                <div class="mt-4 ms-n1 fw-500 font-size-15" :class="{'text-danger': shortFallCurrentValue < 0}" v-if="amountsCurrentValue">
                    <span v-if="shortFall < 0">Shortfall</span><span v-else>Surplus</span> now: {{ bentoCurrencyFormat(Math.abs(shortFallCurrentValue), 0) }}
                </div>
                <div class="mt-4 ms-n1 fw-500 font-size-15" :class="{'text-danger': shortFall < 0}" v-else>
                    <span v-if="shortFall < 0">Shortfall</span><span v-else>Surplus</span> at retirement: {{ bentoCurrencyFormat(Math.abs(shortFall), 0) }}
                </div>

                <div class="ms-n1 fw-500">
                    <span>Based on current income: {{ bentoCurrencyFormat(monthlyIncome) }}</span>
                </div>
            </card-general-inner>

            <!--other blocks-->
            <div class="row g-2 mt-2">
                <div class="col-md-6">
                    <retirement-calculator-input-card-with-currency
                        :inputValue="totalSavings" v-model="totalSavings" @change="calculateHandler"
                        title="Total savings to date" field-description="Saved towards retirement already" field-id="total-savings-cont"
                    />
                </div>
                <div class="col-md-6">
                    <retirement-calculator-input-card-with-currency
                        :input-value="monthlySavings" v-model="monthlySavings" @change="calculateHandler"
                        title="Monthly savings" field-description="Total amount you save toward retirement monthly" field-id="total-monthly-savings"
                    />
                </div>
                <div class="col-md-6">
                    <retirement-calculator-slder-input-card
                        :input-value="retirementAge" v-model="retirementAge" @change="calculateHandler" :death-age="deathAge"
                        field-id="retirement-age" title="Retirement age" field-description="Age you would stop earning a salary"
                        :minValue="minRetirementAge" :max-value="80" :age-field="true"
                    />
                </div>
                <div class="col-md-6">
                    <retirement-calculator-slder-input-card
                        :inputValue="deathAge" v-model="deathAge" @change="calculateHandler" :retirement-age="retirementAge"
                        field-id="death-age" title="Age you need money until" field-description="Your life expectancy"
                        :min-value="80" :max-value="100" :age-field="true"
                    />
                </div>
                <div class="col-md-6">
                    <retirement-calculator-slder-input-card
                        :inputValue="percentageRequiredPercent" v-model="percentageRequiredPercent" @change="calculateHandler"
                        field-id="percentage-required" title="Required income in retirement" field-description="As a percentage(%) of your current income"
                        :min-value="50" :max-value="100" :is-percentage="true"
                    />
                </div>
                <div class="col-md-6">
                    <retirement-calculator-slder-input-card
                        :inputValue="netReturnExpectedPercent" v-model="netReturnExpectedPercent" @change="calculateHandler"
                        field-id="net-return-expected" title="Net return expected" field-description="Annual percentage(%) return on savings"
                        :min-value="6" :max-value="20" :is-percentage="true"
                    />
                </div>
            </div>

            <retirement-calculator-summary-table
                v-if="false"
                :projected-amount="projectedAmount"
                :age-you-can-retire-until="ageYouCanRetireUntil"
                :target-amount="targetAmount"
                :percentage-required-percent="percentageRequiredPercent"
                :expected-return-on-investment="netReturnExpectedPercent"
            />

            <div class="mt-4 d-flex flex-wrap flex-md-nowrap">
                <button-bento color="dark" class="me-sm-2" @click="exploreProductOptionsButtonHandler" v-if="isEnabledRetirement">
                    <span>Explore product options</span> <img src="@/assets/v2/icons/arrows/arrow-right-white.svg" class="arrow"/>
                </button-bento>
                <button-bento color="white-bg-green" class="ms-md-2 mt-3 mt-sm-0" @click="resetCalculatorHandler">
                    <span>Reset Calculator</span>
                </button-bento>
            </div>
        </div>
    </card-general>
</template>

<script setup>
import CardGeneral from '@/components/UI/v2/containers-cards-headers/CardGeneral.vue'
import SectionHeader from '@/components/UI/v2/containers-cards-headers/SectionHeader.vue'
import RetirementInterfaceSwitch
    from '@/components/benefits-leave-reimbursements/retirement/components/RetirementInterfaceSwitch.vue'
import CardGeneralInner from '@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue'
import ButtonBento from '@/components/UI/v2/buttons/ButtonBento.vue'
import RetirementCalculatorSummaryTable
    from '@/components/benefits-leave-reimbursements/retirement/components/calculator/RetirementCalculatorSummaryTable.vue'
import RetirementCalculatorAgeBar
    from '@/components/benefits-leave-reimbursements/retirement/components/calculator/RetirementCalculatorAgeBar.vue'
import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
import {ref, computed, nextTick} from "vue"
import {useStore} from "vuex"
import debounce from 'lodash/debounce'
import useRetirementCalculator from "@/hooks/retirement/retirement-calculator"
import RetirementCalculatorSlderInputCard
    from "@/components/benefits-leave-reimbursements/retirement/components/calculator/RetirementCalculatorSlderInputCard.vue"
import {useRouter} from "vue-router"
import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue"
import {MDBSwitch} from "mdb-vue-ui-kit"
import RetirementCalculatorInputCardWithCurrency
    from "@/components/benefits-leave-reimbursements/retirement/components/calculator/RetirementCalculatorInputCardWithCurrency.vue";

const store = useStore()

const router = useRouter()
const navigateToSection = () => router.push({path: '/benefits/retirement/how-to-use-the-bento-calculator'})

// toggle current value
const amountsCurrentValue = ref(false)
const toggleAmountsCurrentValue = () => amountsCurrentValue.value = !amountsCurrentValue.value

// tooltips
const extraInfoTargetAmount = `<p class="fw-600">The estimated amount you'll need saved by your retirement age to fund your desired lifestyle until the age you expect to live to.</p>
            <p class="fw-500">It’s based on several factors including your current age, retirement age, life expectancy, annual income, and the percentage of that income you plan to require in retirement. It also takes into account expected investment returns and inflation.</p>`

const extraInfoProjectedAmount = `<p class="fw-600">An estimate of how much you'll have saved by your chosen retirement age.</p>
            <p class="fw-500">It's based on your current savings balance, annual income, and the percentage of that income you're contributing to retirement. Like the "Target Amount," it's also affected by expected investment returns and inflation. Keep in mind that this is a projection and actual results may vary.</p>`

const extraInfoAgeYouCanRetireUntil = `<p class="fw-600">Age at which retirement savings will run out.</p>
            <p class="fw-500">Ideally, this age should be greater than your life expectancy to ensure financial security throughout your entire retirement.</p>`

const extraInfoInTodaysMoney = `<p class="fw-600">Switching to 'Today's terms' helps you understand the future value of your savings in terms of today's buying power.</p>
            <p class="fw-500">"Nominal amount" shows what you'll need or have by your retirement age, while "Today’s terms" adjusts these figures to today's monetary value, accounting for inflation.</p>`


const isEnabledRetirement = store.getters.isEnabledRetirement

const calculatorRetirementContract = computed(() => store.getters.calculatorRetirementContract)
const retirementCalculatorInputs = store.getters.retirementCalculatorInputs

const monthlyIncome = Number(store.getters.monthlyIncome)
const totalSavings = ref(retirementCalculatorInputs.totalSavings)

const percentageSavedStart = ref(0)

const monthlySavings = ref(Math.round(calculatorRetirementContract?.value?.totalContribution || 0))


const retirementAge = ref(retirementCalculatorInputs.retirementAge)
const deathAge = ref(retirementCalculatorInputs.deathAge)
const percentageRequiredPercent = ref(retirementCalculatorInputs.percentageRequired * 100)
const netReturnExpectedPercent = ref((retirementCalculatorInputs.returnAbove + retirementCalculatorInputs.inflation) * 100)

const minRetirementAge = computed(() => (store.getters.age > 40) ? Number(store.getters.age) : 40)


// I want to change the levers in the input field
// Which should then update the
// 1. target amount
// 2. projected amount
// 3. age you can retire until
const targetAmount = ref(0)
const targetAmountToday = ref(0)
const projectedAmount = ref(0)
const projectedAmountToday = ref(0)
const shortFall = computed(() => projectedAmount.value - targetAmount.value)
const shortFallCurrentValue = computed(() => projectedAmountToday.value - targetAmountToday.value)
const ageYouCanRetireUntil = ref(0)

const percentageSaved = computed(() => {
    if (monthlySavings.value && monthlyIncome) {
        return Number(monthlySavings.value) / Number(monthlyIncome)
    } else {
        return 0
    }
})

const debouncedCalculatorCommit = debounce(() => {
    store.commit('setCalculatorRetirementContract', {totalContribution: monthlySavings.value, fundId: null});
}, 500)

const calculateHandler = () => {

    const {
        targetAmount: target,
        targetAmountToday: targetToday,
        projectedAmount: projected,
        projectedAmountToday: projectedToday,
        ageYouCanRetireUntil: age
    } = useRetirementCalculator(
        retirementAge.value,
        deathAge.value,
        totalSavings.value,
        percentageSaved.value,
        percentageRequiredPercent.value,
        netReturnExpectedPercent.value
    )

    targetAmount.value = target
    targetAmountToday.value = targetToday
    projectedAmount.value = projected
    projectedAmountToday.value = projectedToday
    ageYouCanRetireUntil.value = age

    debouncedCalculatorCommit()
}

calculateHandler()

const resetCalculatorHandler = () => {
    retirementAge.value = retirementCalculatorInputs.retirementAge
    deathAge.value = retirementCalculatorInputs.deathAge
    totalSavings.value = retirementCalculatorInputs.totalSavings
    percentageSavedStart.value = retirementCalculatorInputs.percentageSavedStart
    monthlySavings.value = 0
    percentageRequiredPercent.value = retirementCalculatorInputs.percentageRequired * 100
    netReturnExpectedPercent.value = (retirementCalculatorInputs.returnAbove + retirementCalculatorInputs.inflation) * 100

    const {
        targetAmount: target,
        targetAmountToday: targetToday,
        projectedAmount: projected,
        projectedAmountToday: projectedToday,
        ageYouCanRetireUntil: age
    } = useRetirementCalculator(
        retirementAge.value,
        deathAge.value,
        totalSavings.value,
        percentageSaved.value,
        percentageRequiredPercent.value,
        netReturnExpectedPercent.value
    )
    targetAmount.value = target
    targetAmountToday.value = targetToday
    projectedAmount.value = projected
    projectedAmountToday.value = projectedToday
    ageYouCanRetireUntil.value = age

    debouncedCalculatorCommit()
}

const exploreProductOptionsButtonHandler = async () => {
    store.commit('setSelectedRetirementView', 'retirement-investment-options')
    await nextTick()
    let element = document.getElementById('retirement-investment-options')
    if (element) {
        const elementRect = element.getBoundingClientRect()
        const offset = 70 // Replace with your navbar height
        const topPosition = elementRect.top + window.pageYOffset - offset
        window.scrollTo({top: topPosition, behavior: 'smooth'})
    }
}

const projectedAmountClassList = computed(() => {
    return {
        'made-it-green': projectedAmount.value >= targetAmount.value,
        'short-danger': projectedAmount.value < targetAmount.value
    }
})
</script>

<style scoped lang="scss">
@import '@/styles/global-scss/variables-and-mixins.scss';

#current-value-switch {
    transform: scale(1.3);
    position: relative;
    top: 3px;
}

.todays-money-switch-container {
    @media (max-width: 502px) {
        display: none;
    }
    margin-top: -68px;
}
.calculator-only {
    .todays-money-switch-container {
        margin-top: -20px;
    }
}

.in-todays-money-notice {
    padding-left: 5px;
    min-height: 18px;
}

.top-figures-col {
    font-size: 18px;
    padding: 0 4px;
    margin-bottom: 8px;
    @include media-breakpoint-up(md) {
        padding: 0;
    }
    .disclaimer-text {
        position: relative;
        top: -3px;
    }
    &#age-col {
        flex: 0 0 100%;
        .top-figures-col-value {
            text-align: left;
        }
        @include media-breakpoint-up(sm) {
            flex: 0 1 auto;
        }
    }
    @media (max-width: 410px) {
        flex: 0 0 100% !important;
    }
    @include media-breakpoint-up(sm) {
        font-size: 21px;
    }
    @include media-breakpoint-up(md) {
        font-size: 22px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 23px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 24px;
    }
}

#target-age-text-block {
    display: none;
    @media (max-width: 410px) {
        display: block;
    }
}

#retirement-calculator-age-bar {
    @media (max-width: 410px) {
        display: none;
    }
}

.arrow {
    margin-left: 10px;
    margin-bottom: 2px;
    width: 16px;
    @media (max-width: 361px) {
        display: none;
    }
}
button {
    flex: 0 0 100%;
    @include media-breakpoint-up(sm) {
        flex: 0 1 auto;
    }
}

.made-it-green {
    color: $gradient-primary-2;
}
.short-danger {
    color: $danger;
}
</style>
