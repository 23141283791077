<template>
    <div>
        <loading-full-screen v-model="isDownloadingPayslip" loadingText="Fetching PDF payslip ..."/>
        <width-container class="mb-5">
            <card-general>
                <div class="download-icon-cont cursor-pointer" @click="downloadPayslipHandler">
                    <img
                        class="download-icon"
                        src="@/assets/v2/icons/other/download.svg" alt="Download"/> Download
                </div>
                <div v-if="apiResponseError">
                    <bento-alert class="mb-3" color="danger" static dismiss v-model="apiResponseError">
                        {{ apiResponseErrorNotice }}
                    </bento-alert>
                </div>
                <div class="pt-4 pt-sm-1 pt-md-0">
                    <div class="d-flex justify-content-between align-items-center mb-4 pb-3" v-if="hasPayslips">
                        <div>
                            <section-header :no-margin-bottom="true" class="mb-1">Payslip
                                <span class="large-dash">-</span><span class="small-line-break"><br></span>
                                <span class="text-primary">{{ getPayrun(currentPayslip) }}</span></section-header>
                        </div>


                        <div class="d-flex align-items-center justify-content-end">
                            <div class="d-flex align-items-center justify-content-end">
                                <div @click="goToLatest" class="gradient-green-text-dark fw-600 font-size-13 cursor-pointer me-4 hover-primary text-uppercase large-latest-payslip" v-if="currentPage !== 1">
                                    <i class="fa-solid fa-rotate-left"></i> Latest Payslip
                                </div>
                                <div class="text-end">
                                    <button @click="goToPrevious" :disabled="currentPage === totalPages" type="button" role="button" class="btn btn-link text-dark ripple-surface mb-2">
                                        <i class="fas fa-chevron-left font-size-18" aria-hidden="true"></i></button>
                                    <button @click="goToNext" :disabled="currentPage === 1" type="button" role="button" class="btn btn-link text-dark ripple-surface ripple-surface-dark mb-2">
                                        <i class="fas fa-chevron-right font-size-18" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div @click="goToLatest" class="gradient-green-text-dark fw-600 font-size-13 cursor-pointer hover-primary text-uppercase small-latest-payslip mt-n4 mb-5 text-end" v-if="currentPage !== 1 && hasPayslips">
                        <i class="fa-solid fa-rotate-left"></i> Latest Payslip
                    </div>

                    <div class="payslip-cont d-flex flex-wrap flex-md-nowrap">
                        <div class="sidebar-cont order-2 order-md-1 pe-md-4">
                            <payslip-content-sidebar/>
                        </div>

                        <div class="payslip-figures-cont order-1 order-md-2">
                            <div v-if="!hasPayslips">
                                <bento-alert color="warning" static v-model="noPayslipsNotice">You do not currently have any finalised payslips.</bento-alert>
                            </div>

                            <div v-else>
                                <payslip-section
                                    section-header="Income"
                                    :section-total="income.sectionTotal.value"
                                    :section-list="income.sectionList"
                                    section-id="payslip-income-section"
                                />

                                <payslip-section
                                    section-header="Allowances"
                                    :section-total="allowances.sectionTotal.value"
                                    :section-list="allowances.sectionList"
                                />

                                <payslip-section
                                    section-header="Deductions"
                                    :section-total="deductions.sectionTotal.value"
                                    :section-list="deductions.sectionList"
                                />

                                <payslip-section
                                    section-header="Reimbursements"
                                    :section-total="reimbursements.sectionTotal.value"
                                    :section-list="reimbursements.sectionList"
                                />

                                <payslip-section
                                    section-header="Employer Contributions"
                                    :section-total="employerContributions.sectionTotal.value"
                                    :section-list="employerContributions.sectionList"
                                />

                                <payslip-section
                                    section-header="Benefits"
                                    :section-total="benefits.sectionTotal.value"
                                    :section-list="benefits.sectionList"
                                />

                                <payslip-section
                                    section-header="Taxable Income Deduction"
                                    :section-total="taxableIncomeDeduction.sectionTotal.value"
                                    :section-list="taxableIncomeDeduction.sectionList"
                                />

                                <payslip-section
                                    section-header="Tax Credit"
                                    :section-total="taxCredit.sectionTotal.value"
                                    :section-list="taxCredit.sectionList"
                                />


                                <div class="take-home-pay">
                                    <div class="item item-header">
                                        <div class="label">
                                            <span class="nett-pay-span-label">Take Home Pay (Nett)</span>
                                        </div>
                                        <div class="value">
                                            {{ bentoCurrencyFormat(currentPayslip?.nett_pay || 0) }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </card-general>
        </width-container>
    </div>
</template>

<script setup>
import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import PayslipContentSidebar from '@/components/summary-and-payslip/payslip/payslip-content/PayslipContentSidebar.vue'
import {getPayrun, payslipReimbursementsCalc} from "@/hooks/payslip-and-financials/get-payslip-data-from-payslip"
import {
    payslipDeductionsCalc,
    payslipIncomeCalc,
    payslipAllowancesCalc,
    payslipEmployerContributionsCalc,
    payslipBenefitsCalc,
    payslipTaxableIncomeDeductionCalc,
    payslipTaxCreditCalc
} from "@/hooks/payslip-and-financials/get-payslip-data-from-payslip"
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
import PayslipSection from "@/components/summary-and-payslip/payslip/payslip-sections/PayslipSection.vue"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen.vue";

const store = useStore()

const noPayslipsNotice = ref(true)

// Reverse the array to ensure the latest payslip is first and then take the first 12
const payslipsList = computed(() => store.getters.payslipsList)
const payslipsSorted = computed(() => {
    if (!isValidArray(payslipsList.value)) return []
    const list = [...payslipsList.value].reverse().slice(0, 12)
    return list
})
const hasPayslips = isValidArray(payslipsSorted.value)

// pagination
const currentPage = ref(1)
const totalPages = computed(() => payslipsSorted.value.length)
const currentPayslip = computed(() => {
    const index = currentPage.value - 1
    return payslipsSorted.value[index]
})

const goToPrevious = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++
    }
}

const goToNext = () => {
    if (currentPage.value > 1) {
        currentPage.value--
    }
}

const goToLatest = () => currentPage.value = 1


// payslip sections
const income = computed(() => payslipIncomeCalc(currentPayslip.value))
const allowances = computed(() => payslipAllowancesCalc(currentPayslip.value))
const deductions = computed(() => payslipDeductionsCalc(currentPayslip.value))
const reimbursements = computed(() => payslipReimbursementsCalc(currentPayslip.value))
const employerContributions = computed(() => payslipEmployerContributionsCalc(currentPayslip.value))
const benefits = computed(() => payslipBenefitsCalc(currentPayslip.value))
const taxableIncomeDeduction = computed(() => payslipTaxableIncomeDeductionCalc(currentPayslip.value))
const taxCredit = computed(() => payslipTaxCreditCalc(currentPayslip.value))


// download payslip handler
const isDownloadingPayslip = ref(false)
const apiResponseError = ref(false)
const apiResponseErrorNotice = ref(false)

const downloadPayslipHandler = async () => {
    isDownloadingPayslip.value = true

    try {
        await store.dispatch('downloadPayslip', {payslip: currentPayslip.value})
    } catch (error) {
        apiResponseError.value = true
        apiResponseErrorNotice.value = error.message
    }

    isDownloadingPayslip.value = false
}


</script>

<style scoped lang="scss">
@import "@/styles/global-scss/variables-and-mixins.scss";

.sidebar-cont {
    flex: 0 0 100%;
    @include media-breakpoint-up(md) {
        max-width: 200px;
        flex: 1 auto;
    }
}

.payslip-figures-cont {
    flex: 0 0 100%;
    @include media-breakpoint-up(md) {
        flex: 1 auto;
    }
}

.large-dash {
    display: none;
}
.small-line-break br {
    display: block;
}
@media (min-width: 420px) {
    .large-dash {
        display: inline-block;
    }
    .small-line-break {
        display: none;
    }
}

.download-icon-cont {
    position: absolute;
    left: 50%;
    top: 8px;
    transform: translateX(-50%);
    text-transform: uppercase;
    color: $primary;
    font-weight: 600;
    font-size: 13px;
    transition: transform 0.2s ease;

    .download-icon {
        display: inline-block;
        transition: transform 0.2s ease;
    }

    &:hover {
        .download-icon {
            transition: transform 0.2s ease;
            transform: scale(1.14);
        }
    }
}

.large-latest-payslip {
    display: none;
}
@media (min-width: 580px) {
    .small-latest-payslip {
        display: none;
    }
    .large-latest-payslip {
        display: block;
    }
}

button {
    background: #f5f5f5;
    &:hover {
        background: #c7f1ea;
    }
}

.item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 3px 0;
    padding-left: 14px;
    font-size: 14px;
    font-weight: 500;
    @include media-breakpoint-up(md) {
        padding-left: 24px;
        font-size: 15px;
    }
}

.item.item-header {
    padding-left: 0;
    .label {
        padding-right: 10px;
    }
    .label, .value {
        font-weight: 700;
        font-size: 16px;
        color: #000;
        @include media-breakpoint-up(sm) {
            font-size: 17px;
        }
        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }
}

.take-home-pay {
    margin-bottom: 35px;
    .label {
        span.nett-pay-span-label {
            display: inline-block;
            border-bottom: solid black 2px;
        }
    }
    .value {
        border-bottom: solid black 2px;
    }
}
</style>