// employer module getters
import {isNonEmptyString} from "@/hooks/general/type-and-value-checks";

export default {
    employer: (state) => state.employer,
    employerName: (_, getters) => isNonEmptyString(getters.employer.friendly_name) ? getters.employer.friendly_name : getters.employer.name,

    lastEmployerFetch: state => state.lastEmployerFetch,
    shouldUpdateEmployer: (_, getters) => !getters.lastEmployerFetch || ((new Date().getTime() - getters.lastEmployerFetch) / 1000) > 900,

    employerMedicalAidContributionStrategyObj: (_, getters) => getters.employer?.medical_aid_contribution || null,

    // reimbursement dates
    reimbursementClaimStartAndEndDates: (_, getters) => getters.employer?.reimbursement_claim_start_and_end_dates || false,
    reimbursementClaimStartDate: (_, getters) => getters.reimbursementClaimStartAndEndDates?.[0] || false,
    reimbursementClaimEndDate: (_, getters) => getters.reimbursementClaimStartAndEndDates?.[1] || false,
    claimsPeriod: (_, getters) => {
        const startDate = new Date(getters.reimbursementClaimStartDate)
        const startDay = startDate.getDate()

        const endDate = new Date(getters.reimbursementClaimEndDate)
        const endDay = endDate.getDate()

        const startMonthNumber = startDate.getMonth()
        const endMonthNumber = endDate.getMonth()

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
        const startMonthName = monthNames[startMonthNumber]
        const endMonthName = monthNames[endMonthNumber]

        const endYear = endDate.getFullYear()

        return `${startDay} ${startMonthName} - ${endDay} ${endMonthName} ${endYear}`
    },


    // feature enabling - set on employer object
    employerFeatureFlagsList: (_, getters) => getters.employer?.feature_flags || null,

    isEnabledMedicalAid: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'medical_aid') || false,
    isEnabledLeave: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'leave_writing_enabled' || feature.name === 'leave') || false,
    isEnabledReimbursements: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'reimbursements') || false,
    isEnabledRisk: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'risk') || false,
    isEnabledRiskContent: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'risk_content') || false,
    isEnabledRetirement: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'retirement') || false,
    isEnabledRetirementCalculator: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'retirement_calculator') || false,
    isEnabledBonusesAndCommissions: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'bonuses_and_commissions') || false,
    isEnabledOldMutualLifeContributions: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'group_life') || false,

    hideBankDetails: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'hide_bank_details') || false,

    // TODO
    //  I think there is a better way to do this
    isEnabledLeaveShowAnnualBalanceBreakdown: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'leave_show_annual_balance_breakdown') || false,

    // TODO
    //  These are very hacky to make individual employer checks and unique rules for employers on the front-end
    isPayrollPayspace: (_, getters) => getters.employerFeatureFlagsList?.some(feature => feature.name === 'is_payroll_payspace') || false,
    isEmployerBentoDemo: (_, getters) => getters.employer?.id == 6,
    isEmployerRoot: (_, getters) => getters.employer?.id == 2,
    isEmployerCcfoCt: (_, getters) => getters.employer?.id == 14 || getters.employer?.name === "Creative CFO Cape Town",
}