<template>
    <div>

        <div v-if="!selectedPlanDescriptions">
            <p class="carousel-sub-header mt-5">Please select a plan to view the benefits.</p>
        </div>

        <div v-else class="carousel-container">
            <Carousel :settings="settings" :breakpoints="breakpoints">
                <Slide v-for="description in selectedPlanDescriptions" :key="description.section">
                    <div class="carousel__item">
                        <wizard-block-info-card-benefit :description="description"/>
                    </div>
                </Slide>

                <template #addons>
                    <Navigation/>
                    <Pagination/>
                </template>
            </Carousel>
        </div>

    </div>
</template>

<script>
// docs: https://ismail9k.github.io/vue3-carousel/examples.html
import {mapGetters} from "vuex";
import {defineComponent} from 'vue'
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import WizardBlockInfoCardBenefit
    from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/benefit/WizardBlockInfoCardBenefit";

export default defineComponent({
    name: 'WizardBlockCardCarouselBenefits',
    components: {
        WizardBlockInfoCardBenefit,
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },

    computed: {
        ...mapGetters([
            'tempSelectedMedicalAidContractObj',
            'medicalAidPlanList',
        ]),

        selectedPlanDescriptions() {
            const planId = this.tempSelectedMedicalAidContractObj?.plan_id || false;
            if (!planId) return false
            const plan = this.medicalAidPlanList.find(plan => plan.id === planId)
            return plan.descriptions

        },
    },

    data: () => ({

        // carousel settings
        settings: {
            itemsToShow: 1,
            snapAlign: 'start',
            wrapAround: true,
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
            // 700px and up
            960: {
                itemsToShow: 2.95,
                snapAlign: 'start',
            },
            // 1024 and up
            1560: {
                itemsToShow: 3.95,
                snapAlign: 'start',
            },
        },

    }),
});
</script>

<style lang="scss">
@import "@/styles/global-scss/variables-and-mixins.scss";

// UNSCOPED WHICH OTHER CAROUSELS TAKE FROM

.carousel-container {
    margin-top: 15px;
}

.carousel__item {
    width: 100%;
    font-size: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 10px;
}

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 1px solid black;
    background-color: white;
    &:hover {
        background-color: $primary-light;
    }
    path {
        fill: black;
    }
}

/*from active classes example*/
.carousel__slide > .carousel__item {
    transform: scale(1);
    opacity: 0.8;
    transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
    opacity: 1;
    transform: rotateY(0);
}
//.carousel__slide--next > .carousel__item {
//    transform: scale(0.95) translate(-10px);
//}
//.carousel__slide--prev > .carousel__item {
//    transform: scale(0.95) translate(10px);
//}
//.carousel__slide--active > .carousel__item {
//    transform: scale(1.05);
//}

/*ross extra styles*/
.carousel__pagination-button {
    background-color: $grey;
}
.carousel__pagination-button--active {
    background-color: $primary-light;
}

.carousel-sub-header {
    font-size: 14px;
    font-weight: 500;
    color: #555;
}
</style>