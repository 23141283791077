<template>
    <div class="page-container">
        <loading-full-screen v-model="isLoading" loadingText="Loading terms..."/>

        <div v-if="!isLoading">
            <div class="main-content-container px-4">
                <page-header>Terms & Privacy Policy</page-header>
            </div>

            <div class="main-container">
                <div class="main-content-container justify-content-center">

                    <div class="terms-container flex-grow-1">

                        <div class="terms-scroll-container">

                            <!--end user terms-->
                            <div class="policy-container cursor-pointer" @click="endUserTermsExpanded = !endUserTermsExpanded">
                                <div class="policy-header">
                                    <div class="title-and-date">
                                        <h3>End User Terms of Services</h3>
                                        <div class="effective-date">
                                            {{ userTermsEffectiveDate }}
                                        </div>
                                    </div>
                                    <div class="expand-container">
                                        <three-dots-toggle/>
                                    </div>
                                </div>

                                <div class="terms-content" v-show="endUserTermsExpanded">
                                    <div
                                        class="term"
                                        v-for="term in userTermsTerms"
                                        :key="term.term_heading + Math.random()"
                                    >
                                        <div class="term-heading">{{ term.term_heading }}</div>
                                        <div class="term-text" v-html="term.term_text"></div>

                                    </div>
                                </div>
                            </div>

                            <!--privacy policy-->
                            <div class="policy-container cursor-pointer" @click="privacyPolicyExpanded = !privacyPolicyExpanded">

                                <div class="policy-header">
                                    <div class="title-and-date">
                                        <h3>Privacy Policy</h3>
                                        <div class="effective-date">
                                            {{ ppEffectiveDate }}
                                        </div>
                                    </div>
                                    <div class="expand-container">
                                        <three-dots-toggle/>
                                    </div>
                                </div>

                                <div
                                    v-show="privacyPolicyExpanded"
                                    class="terms-content"
                                >
                                    <div
                                        class="term"
                                        v-for="term in ppTerms"
                                        :key="term.term_heading + Math.random()"
                                    >
                                        <div class="term-heading">
                                            {{ term.term_heading }}
                                        </div>
                                        <div
                                            class="term-text"
                                            v-html="term.term_text"
                                        ></div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import LoadingFullScreen from '@/components/UI/v2/loading/LoadingFullScreen'
import ThreeDotsToggle from '@/components/UI/v2/misc/ThreeDotsToggle.vue'
import PageHeader from '@/components/UI/v2/containers-cards-headers/PageHeader.vue'

const isLoadingEmployee = ref(false)
const isLoadingPrivacyPolicyContent = ref(false)
const isLoadingUserTermsContent = ref(false)
const endUserTermsExpanded = ref(false)
const privacyPolicyExpanded = ref(false)

const store = useStore()

const isLoading = computed(() => {
    return isLoadingPrivacyPolicyContent.value || isLoadingUserTermsContent.value || isLoadingEmployee.value
})

const endUserTermsContent = computed(() => store.getters['userTermsContent'])
const userTermsEffectiveDate = computed(() => endUserTermsContent.value['effective_date_sub-title'])
const userTermsTerms = computed(() => endUserTermsContent.value["legal_page_content"][0]["term"])

const privacyPolicyContent = computed(() => store.getters['privacyPolicyContent'])
const ppEffectiveDate = computed(() => privacyPolicyContent.value["effective_date_sub-title"])
const ppTerms = computed(() => privacyPolicyContent.value["legal_page_content"][0]["term"])


const loadUserTermsContent = async () => {
    isLoadingUserTermsContent.value = true
    try {
        await store.dispatch('fetchUserTerms')
    } catch (error) {
        console.error(error.message || 'Error: Could not load User Terms content!')
    }
    isLoadingUserTermsContent.value = false
}

const loadPrivacyPolicyContent = async () => {
    isLoadingPrivacyPolicyContent.value = true
    try {
        await store.dispatch('fetchPrivacyPolicy')
    } catch (error) {
        console.error(error.message || 'Error: Could not load Privacy Policy content!')
    }
    isLoadingPrivacyPolicyContent.value = false
}

onMounted(() => {
    loadUserTermsContent()
    loadPrivacyPolicyContent()
})
</script>

<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";

.page-container {
    position: relative;
}

/*main content structure*/
.main-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: nowrap;

    padding: 15px 20px 105px;
    @media (max-width: 440px) {
        padding: 15px 5px 105px;
    }
}

.main-content-container {
    flex: 1 auto;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }
}

.terms-container {
    background: rgba(255, 255, 255, 0.89);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 18px 16px 30px 24px;

    @media (max-width: 440px) {
        padding: 18px 10px 30px 15px;
    }

    @include media-breakpoint-up(lg) {
        padding: 20px 20px 30px 30px;
    }
}

.terms-scroll-container {
    height: calc(100vh - 250px - 135px);
    max-height: 390px;
    padding-right: 14px;
    overflow-y: auto;
    direction: ltr;
    scrollbar-color: #d4aa70 #e4e4e4;
    scrollbar-width: thin;

    @include media-breakpoint-up(sm) {
        padding-right: 20px;
    }
    @include media-breakpoint-up(md) {
        max-height: 440px;
    }
    @include media-breakpoint-up(lg) {
        max-height: 500px;
    }

    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        border: 3px solid transparent;
        background-clip: content-box;
        background-color: $primary;
    }
}

/*terms blocks*/
p {
    line-height: 1.5;
}

.policy-container {
    margin-bottom: 35px;
    padding: 14px 30px 14px 14px;
    border: 1px solid black;
    background-color: white;

    @include media-breakpoint-up(sm) {
        padding: 17px;
    }

    @include media-breakpoint-up(sm) {
        padding: 20px;
    }

    @include media-breakpoint-up(md) {
        padding: 22px;
    }

    @include media-breakpoint-up(lg) {
        padding: 25px;
    }
}

.policy-header {
    position: relative;
}

.expand-container {
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 440px) {
        right: -30px;
    }

    @include media-breakpoint-up(md) {
        right: 0;
    }
}

h3 {
    margin-top: 0;
}

.effective-date {
    font-size: 16px;
    font-weight: 500;
}

.term {
    margin: 35px 0;
}

.term-heading {
    font-weight: 600;
    margin-bottom: 15px;
}

.term-text {
    font-size: 14px;
    line-height: 1.4;
}
</style>