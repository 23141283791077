<template>
    <div class="inner-green-block bg-primary-50 px-2 px-sm-3 px-md-5 py-4 bento-border-radius mb-5">
        <div>
            <h3 class="gradient-green-text-dark font-size-18 d-inline-block">{{ new Date().getFullYear() }} Annual Balance Summary</h3>
            <div class="font-size-14 gradient-green-text-dark">You get <span class="fw-600">17 days</span> of paid leave per year - accrued up front at the beginning of the year.</div>
        </div>


        <div class="leave-summary-table mt-2 font-size-15 gradient-green-text-dark lh-sm">

            <div class="fw-500 mt-4">
                <span class="bento-underline-dark-green">Balances</span>
            </div>
            <div class="font-size-14">
                <div class="d-flex my-2">
                    <div class="label">Current balance:</div>
                    <div class="value">{{ currentBalance }} days
                        <info-tooltip
                            :tip-content="currentBalanceSummaryTooltip" v-if="currentBalanceSummaryTooltip"
                            class="gradient-green-text-dark d-inline-block ms-3"
                        />
                    </div>
                </div>
                <div class="d-flex my-2">
                    <div class="label">{{ new Date().getFullYear() }} future approved days:</div>
                    <div class="value">{{ totalApprovedDaysThisYearFuture }} {{ totalApprovedDaysThisYearFuture === 1 ? 'day' : 'days' }}</div>
                </div>
                <div class="d-flex my-2">
                    <div class="label">{{ new Date().getFullYear() }} leave remaining:</div>
                    <div class="value">{{ leaveDaysLeftInYear }} days
                        <info-tooltip
                            :tip-content="remainingBalanceSummaryTooltip" v-if="remainingBalanceSummaryTooltip"
                            class="gradient-green-text-dark d-inline-block ms-3"
                        />
                    </div>
                </div>
            </div>

            <div class="font-size-11 gradient-green-text-light mt-3">Employment date: {{ longDateFormat(dateOfEmployment) }}</div>
        </div>

        <div class="separator mb-4 mt-3"></div>

        <div class="mt-2">
            <div class="fw-500 ms-1 gradient-green-text-dark font-size-15 hover-primary my-2">
                <a class="cursor-pointer" @click="scrollToHash('leave-requests-table')">View all leave requests -></a>
            </div>
            <div class="fw-500 ms-1 gradient-green-text-dark font-size-15 hover-primary">
                <a class="cursor-pointer" @click="scrollToHash('benefit-resources')">Read more about {{ employerName }}'s leave policies -></a>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useStore} from "vuex"
import {computed} from "vue"
import {scrollToHash} from "@/hooks/general/navigation"
import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue"
import {longDateFormat} from "@/hooks/general/date-helpers"
import {roundToTwoDecimal} from "@/hooks/general/currency-number-formatters";

const store = useStore()

const employerName = store.getters.employerName
const leaveBalancesSorted = store.getters.leaveBalancesSorted
const leaveRequestList = computed(() => store.getters.leaveRequestList)
const dateOfEmployment = store.getters.employee.date_of_employment

// const isEmployementStartDateThisYear = computed(() => new Date(dateOfEmployment).getFullYear() === new Date().getFullYear())

const annualLeaveObj = computed(() => leaveBalancesSorted.find(leaveObj => leaveObj.name.toLowerCase().includes('annual')))
const currentBalance = computed(() => roundToTwoDecimal(annualLeaveObj.value?.balance) || 0)

const leaveRequestsThisYear = computed(() => {
    const currentYear = new Date().getFullYear()
    const thisYearRequests = leaveRequestList.value.filter(request => {
        const startDate = new Date(request.start_date)
        return startDate.getFullYear() === currentYear
    })
    return thisYearRequests.filter(request => request.leave_type_name.toLowerCase() === 'annual')
})

const approvedLeaveRequestsThisYear = computed(() => {
    return leaveRequestsThisYear.value.filter(request => request.state === 'approved')
})

const totalApprovedDaysThisYearFuture = computed(() => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const futureApprovedRequests = approvedLeaveRequestsThisYear.value.filter(request => new Date(request.start_date) > currentDate)

    const isWeekend = (date) => {
        const day = date.getDay()
        return day === 0 || day === 6 // Sunday or Saturday
    }

    const totalFutureApprovedDays = futureApprovedRequests.reduce((acc, request) => {
        const endDate = new Date(request.end_date)

        let daysInNewYear = 0
        if (endDate.getFullYear() > currentYear) {
            for (let d = new Date(currentYear + 1, 0, 2); d <= endDate; d.setDate(d.getDate() + 1)) {
                if (!isWeekend(d)) {
                    daysInNewYear++
                }
            }
        }

        return acc + request.days - daysInNewYear
    }, 0)

    return totalFutureApprovedDays
})

const leaveDaysLeftInYear = computed(() => {
    return roundToTwoDecimal(currentBalance.value - totalApprovedDaysThisYearFuture.value)
})

const currentBalanceSummaryTooltip = computed(() => {
    return `<p>Your leave balance today. Your balance carried over from last year, plus 17 days of leave accrued up front at the beginning of the year, less any leave already taken this year.</p>`
})

const remainingBalanceSummaryTooltip = computed(() => {
    return `<p>Your current balance minus any approved leave requests this year.</p>`
})
</script>

<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";
.leave-summary-table {
    .label {
        flex: 0 0 210px;
        font-weight: 400;
    }
    .value {
        font-weight: 500;
    }
}
</style>